import { Grid } from "@mui/material";
import { styled as styledM } from "@mui/material/styles";
import styled from "styled-components";

export const GridInput = styledM(Grid)(() => ({
  padding: "10px 10px 10px 0px",
}));

export const ContainerButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  span {
    margin: 0px 20px;
  }
`;
