import { http } from "../http/http";

const URL = process.env.REACT_APP_URL;

export const moduleRepository = {
  getAllModule: async (token: string) => {
    const response = await http.get(`${URL}/modules`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  getModulesByProfile: async (id: string, token: string) => {
    const response = await http.get(`${URL}/modules/${id}/profile`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  getModuleById: async (id: string, token: string) => {
    const response = await http.get(`${URL}/modules/${id}`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
};
