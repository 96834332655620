import { User } from "../../../domain/models/User";

export const validatorCollector = (collector: User, error: any) => {
  let {
    dniErrorText,
    dniError,
    nameErrorText,
    nameError,
    lastNameError,
    lastNameErrorText,
    userError,
    userErrorText,
    passwordError,
    passwordErrorText,
  } = error;
  const { user, password, dni, lastname, name } = collector;

  if (dni === "") {
    dniError = true;
    dniErrorText = "Ingrese dni";
  } else if (dni.trim().length < 8) {
    dniError = true;
    dniErrorText = "Ingrese dni valido";
  } else {
    dniError = false;
    dniErrorText = "";
  }

  if (name === "") {
    nameError = true;
    nameErrorText = "Ingrese contraseña";
  } else {
    nameError = false;
    nameErrorText = "";
  }

  if (lastname === "") {
    lastNameError = true;
    lastNameErrorText = "Ingrese contraseña";
  } else {
    lastNameError = false;
    lastNameErrorText = "";
  }

  if (user === "") {
    userError = true;
    userErrorText = "Ingrese email";
  } else {
    userError = false;
    userErrorText = "";
  }

  if (password === "") {
    passwordError = true;
    passwordErrorText = "Ingrese contraseña";
  } else {
    passwordError = false;
    passwordErrorText = "";
  }

  if (
    userError ||
    passwordError ||
    dniError ||
    nameError ||
    lastNameError ||
    (passwordError && userError && dniError && nameError && lastNameError)
  ) {
    return {
      dniErrorText,
      dniError,
      nameErrorText,
      nameError,
      lastNameError,
      lastNameErrorText,
      userError,
      userErrorText,
      passwordError,
      passwordErrorText,
    };
  } else {
    return false;
  }
};
