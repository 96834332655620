import styled from "styled-components";

interface ICardProps {
  infoCustomer: any;
  customer: any;
}

export const Information = styled.div`
  margin: 5px 0px;
  span {
    font-weight: 700;
  }
`;

export const Name = styled.p`
  font-size: 20px;
  font-weight: 700;
`;

export const Card = styled.div<ICardProps>`
  padding: 15px;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2);
  border: 3px solid
    ${(props) =>
      props.infoCustomer?._id === props.customer?._id
        ? props.theme.colors.primary
        : props.theme.colors.default};
  cursor: pointer;
`;

export const Container = styled.div`
  margin: 20px 0px;
`;
