import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0px;
  p {
    font-weight: 700;
    font-size: 18px;
    @media (max-width: 900px) {
      font-size: 12px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0px;
  p {
    font-weight: bold;
  }
`;
