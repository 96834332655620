import React from "react";
import { useParams } from "react-router-dom";
import FormUpdateCollector from "../components/elements/FormUpdateCollector";
import { Paragraph, Title } from "../components/ui/Texts";

const IdCollector = () => {
  const { idCollector } = useParams();

  return (
    <div>
      <Title>Actualizar Cobrador</Title>
      <Paragraph>Aquí podra actualizar datos del cobrador</Paragraph>
      <FormUpdateCollector id={idCollector as string} />
    </div>
  );
};

export default IdCollector;
