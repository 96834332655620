import React from "react";
import CardsModules from "../components/containers/CardsModules";
import { Paragraph, Subtitle, Title } from "../components/ui/Texts";

const PermissionByProfile = () => {
  return (
    <div>
      <Title>Módulos por Perfil</Title>
      <Paragraph>Visualizador de módulos</Paragraph>
      <Subtitle>Lista de módulos</Subtitle>
      <CardsModules />
    </div>
  );
};

export default PermissionByProfile;
