import { Grid } from "@mui/material";
import { styled as styledM } from "@mui/material/styles";
import styled from "styled-components";

export const ImageCustomer = styled.img`
  width: 100%;
  margin-top: 15px;
`;

export const GridInput = styledM(Grid)(() => ({
  padding: "10px 10px 10px 0px",
}));

export const ContainerButtons = styled.div`
  display: flex;
  margin: 20px 0px;
  flex-wrap: wrap;
  div {
    margin: 0px 20px;
  }
`;
