import styled from "styled-components";

export const Name = styled.p`
  font-size: 20px;
  font-weight: 700;
`;

export const Card = styled.div`
  padding: 15px;
  border: 1px solid ${(props) => props.theme.colors.primary};
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;
