import React from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { IconButton } from "@mui/material";
import { ContainerButton } from "./style";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <ContainerButton onClick={() => navigate(-1)}>
      <IconButton>
        <ArrowCircleLeftIcon />
      </IconButton>
      <p>Volver</p>
    </ContainerButton>
  );
};

export default BackButton;
