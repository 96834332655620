import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonPrimary } from "../Buttons";
import { ContainerFound, Paragraph, Subtitle, Title } from "./style";

interface INotFoundProps {
  auth: boolean;
}

const NotFound: React.FC<INotFoundProps> = ({ auth }) => {
  const navigate = useNavigate();

  return (
    <ContainerFound>
      <Title>404</Title>
      <br />
      <br />
      <Subtitle>¡Ocurrio un error inesperado!</Subtitle>
      <br />
      <Paragraph>No hemos podido encontrar la pagina que buscas</Paragraph>
      <br />
      <br />
      {auth ? (
        <>
          <ButtonPrimary click={() => navigate("/financiera")}>
            Ir al dashboard
          </ButtonPrimary>
        </>
      ) : (
        <>
          <ButtonPrimary click={() => navigate("/")}>Ir al login</ButtonPrimary>
        </>
      )}
    </ContainerFound>
  );
};

export default NotFound;
