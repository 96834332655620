import React from "react";
import { TextParagraph, TextSubtitle, TextTitle } from "./style";

interface ITextProps {
  children: string;
}

export const Title: React.FC<ITextProps> = ({ children }) => {
  return <TextTitle>{children}</TextTitle>;
};

export const Subtitle: React.FC<ITextProps> = ({ children }) => {
  return <TextSubtitle>{children}</TextSubtitle>;
};

export const Paragraph: React.FC<ITextProps> = ({ children }) => {
  return <TextParagraph>{children}</TextParagraph>;
};
