import React from "react";
import FormPermission from "../components/elements/FormPermission";
import { Paragraph, Subtitle, Title } from "../components/ui/Texts";

const PermissionByModule = () => {
  return (
    <div>
      <Title>Permisos por Módulo</Title>
      <Paragraph>Visualizador de permisos por módulo</Paragraph>
      <Subtitle>Información del módulo</Subtitle>
      <FormPermission />
    </div>
  );
};

export default PermissionByModule;
