import { Grid } from "@mui/material";
import React from "react";
import { Road } from "../../../../domain/models/Road";
import { roadService } from "../../../../domain/services/road.service";
import { getToken } from "../../../helpers/constants";
import CardRoad from "../../elements/CardRoad";

const CardsRoads = () => {
  const [roads, setRoads] = React.useState<Road[]>([]);

  const getAllRoads = async () => {
    const data = await roadService.getAllRoads(getToken());
    setRoads(data);
  };

  React.useEffect(() => {
    getAllRoads();
  }, []);

  return (
    <div>
      <Grid container spacing={3} style={{ marginTop: 10 }}>
        {roads.map((item: Road, index) => {
          return (
            <Grid item xs={12} md={3} sm={6} key={index}>
              <CardRoad {...item} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default CardsRoads;
