import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Name } from "./style";

interface ICardProfileProps {
  _id: string;
  name: string;
}

const CardProfile: React.FC<ICardProfileProps> = ({ ...item }) => {
  const navigate = useNavigate();

  return (
    <Card onClick={() => navigate(item._id)}>
      <Name>{item.name}</Name>
    </Card>
  );
};

export default CardProfile;
