import React from "react";
import WrapperAuth from "../components/containers/WrapperAuth";
import FormLogin from "../components/elements/FormLogin";

const Login = () => {
  return (
    <WrapperAuth>
      <FormLogin />
    </WrapperAuth>
  );
};

export default Login;
