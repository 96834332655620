import React from "react";
import Slider from "react-slick";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { myTheme } from "../../../assets/styles/my-theme";
import { BookM } from "../../../../domain/models/Book";
import { Card, Information, Name, Title } from "./style";
import { bookService } from "../../../../domain/services/book.service";
import { useNavigate } from "react-router-dom";
import {
  BOOK_PAID_OUT,
  getToken,
  getUser,
  getTypeUser,
  USER_ADMIN,
  BOOK_REFINANCED,
} from "../../../helpers/constants";

interface ISliderBooksProps {
  books: any;
  daySelected: string;
}

const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <ChevronRightIcon
      style={{
        ...style,
        border: `1px solid ${myTheme.colors.primary}`,
        color: `${myTheme.colors.primary}`,
      }}
      className={`${className}`}
      onClick={onClick}
    />
  );
};

const BackArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <ChevronLeftIcon
      style={{
        ...style,
        border: `1px solid ${myTheme.colors.primary}`,
        color: `${myTheme.colors.primary}`,
      }}
      className={`${className}`}
      onClick={onClick}
    />
  );
};

const SliderBooks: React.FC<ISliderBooksProps> = ({ books, daySelected }) => {
  const navigate = useNavigate();

  const [booksPending, setBooksPending] = React.useState<number>(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <BackArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getStatusPaymentToday = (book: BookM) => {
    const color = bookService.getStatusPaymentToday(book, daySelected);
    return color;
  };

  const redirectBook = (book: BookM) => {
    switch (book.idType.name) {
      case "DIARIO":
        navigate(`/financiera/pago-diario/${book._id}`);
        break;
      case "SEMANAL":
        navigate(`/financiera/pago-semanal/${book._id}`);
        break;
      case "QUINCENAL":
        navigate(`/financiera/pago-quincenal/${book._id}`);
        break;
      case "MENSUAL":
        navigate(`/financiera/pago-mensual/${book._id}`);
        break;
      default:
        return null;
    }
  };

  const calculateBooksPending = async () => {
    const booksPending = await bookService.calculateBooksPendingToday(
      daySelected,
      getToken(),
      getUser()
    );
    setBooksPending(booksPending);
  };

  React.useEffect(() => {
    calculateBooksPending();
  }, [books]);

  return (
    <>
      {getTypeUser() === USER_ADMIN ? (
        <div style={{ maxWidth: "80vw", margin: "auto" }}>
          {books?.map((item: any, index: number) => {
            return (
              <div key={index}>
                <Title>Ruta : {item?.routeName}</Title>
                {item.books?.filter(
                  (item: BookM) => item.status !== BOOK_PAID_OUT
                ).length < 3 ? (
                  <div style={{ display: "flex" }}>
                    {item.books
                      ?.filter(
                        (item: BookM) =>
                          item.status !== BOOK_PAID_OUT &&
                          item.status !== BOOK_REFINANCED
                      )
                      .map((item: any, index: number) => {
                        return (
                          <div
                            style={{
                              margin: "0px 20px",
                            }}
                            key={index}
                          >
                            <Card
                              color={getStatusPaymentToday(item)}
                              onClick={() => redirectBook(item)}
                            >
                              <Name>
                                {item.idClient?.name} {item.idClient?.lastname}
                              </Name>
                              <p>{item.idClient?.alias}</p>
                              <Information>
                                Tipo: <span>{item.idType?.name}</span>
                              </Information>
                              <Information>
                                Cuota diaria:{" "}
                                <span>S/{item.dailyFee?.toFixed(2)}</span>
                              </Information>
                              <Information>
                                Monto total: <span>S/{item.totalAmount}</span>
                              </Information>
                            </Card>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <Slider {...settings}>
                    {item.books
                      ?.filter(
                        (item: BookM) =>
                          item.status !== BOOK_PAID_OUT &&
                          item.status !== BOOK_REFINANCED
                      )
                      .map((item: any, index: number) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            key={index}
                          >
                            <Card
                              color={getStatusPaymentToday(item)}
                              onClick={() => redirectBook(item)}
                            >
                              <Name>
                                {item.idClient?.name} {item.idClient?.lastname}
                              </Name>
                              <p>{item.idClient?.alias}</p>
                              <Information>
                                Tipo: <span>{item.idType?.name}</span>
                              </Information>
                              <Information>
                                Cuota diaria:{" "}
                                <span>S/{item.dailyFee?.toFixed(2)}</span>
                              </Information>
                              <Information>
                                Monto total: <span>S/{item.totalAmount}</span>
                              </Information>
                            </Card>
                          </div>
                        );
                      })}
                  </Slider>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div style={{ maxWidth: "80vw", margin: "auto" }}>
          {books?.map((item: any, index: number) => {
            return (
              <div key={index}>
                <Title>Ruta : {item?.routeName}</Title>
                {item.books?.filter(
                  (item: BookM) =>
                    item.status !== BOOK_PAID_OUT &&
                    item.status !== BOOK_REFINANCED &&
                    item.idCollector._id === getUser()._id
                ).length < 3 ? (
                  <div style={{ display: "flex" }}>
                    {item.books
                      ?.filter(
                        (item: BookM) =>
                          item.status !== BOOK_PAID_OUT &&
                          item.status !== BOOK_REFINANCED &&
                          item.idCollector._id === getUser()._id
                      )
                      .map((item: any, index: number) => {
                        return (
                          <div
                            style={{
                              margin: "0px 20px",
                            }}
                            key={index}
                          >
                            <Card
                              color={getStatusPaymentToday(item)}
                              onClick={() => redirectBook(item)}
                            >
                              <Name>
                                {item.idClient.name} {item.idClient.lastname}
                              </Name>
                              <p>{item.idClient.alias}</p>
                              <Information>
                                Tipo: <span>{item.idType.name}</span>
                              </Information>
                              <Information>
                                Cuota diaria:{" "}
                                <span>S/{item.dailyFee?.toFixed(2)}</span>
                              </Information>
                              <Information>
                                Monto total: <span>S/{item.totalAmount}</span>
                              </Information>
                            </Card>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <Slider {...settings}>
                    {item.books
                      ?.filter(
                        (item: BookM) =>
                          item.status !== BOOK_PAID_OUT &&
                          item.status !== BOOK_REFINANCED &&
                          item.idCollector._id === getUser()._id
                      )
                      .map((item: any, index: number) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            key={index}
                          >
                            <Card
                              color={getStatusPaymentToday(item)}
                              onClick={() => redirectBook(item)}
                            >
                              <Name>
                                {item.idClient.name} {item.idClient.lastname}
                              </Name>
                              <p>{item.idClient.alias}</p>
                              <Information>
                                Tipo: <span>{item.idType.name}</span>
                              </Information>
                              <Information>
                                Cuota diaria:{" "}
                                <span>S/{item.dailyFee?.toFixed(2)}</span>
                              </Information>
                              <Information>
                                Monto total: <span>S/{item.totalAmount}</span>
                              </Information>
                            </Card>
                          </div>
                        );
                      })}
                  </Slider>
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default SliderBooks;
