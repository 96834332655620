import { Customer } from "../../domain/models/Customer";
import { http } from "../http/http";

const URL = process.env.REACT_APP_URL;

export const customerRepository = {
  getAllCustomers: async (token: string) => {
    const response = await http.get(`${URL}/clientes`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  postCreateCustomer: async (body: Customer, token: string) => {
    const response = await http.post(
      `${URL}/clientes`,
      JSON.stringify(body),
      token
    );
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  putCustomer: async (id: string, body: Customer, token: string) => {
    const response = await http.put(
      `${URL}/clientes/${id}`,
      JSON.stringify(body),
      token
    );
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  getSearchCustomers: async (word: string, token: string) => {
    const response = await http.get(`${URL}/clientes/search/${word}`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  getIdCustomer: async (id: string, token: string) => {
    const response = await http.get(`${URL}/clientes/${id}`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  deleteIdCustomer: async (id: string, token: string) => {
    const response = await http.delete(`${URL}/clientes/${id}`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
};
