import { Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { BookM } from "../../../../domain/models/Book";
import { bookService } from "../../../../domain/services/book.service";
import { ContainerTable, CustomRow, Title } from "./style";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatStartDate } from "../../../helpers/date";
import { ModalDni } from "../../ui/Modals";

const TableBookCustomer = () => {
  let params = useParams();
  const idBookCustomer = params.idBookCustomer as string;

  const [book, setBook] = React.useState<BookM>();
  const [dniCustomer, setDniCustomer] = React.useState("");
  const [openModal, setOpenModal] = React.useState(true);

  const getBookById = async () => {
    const book = await bookService.getBookByIdPublic(idBookCustomer);
    setBook(book.data);
    setDniCustomer(book.data?.idClient.dni);
  };

  const validateDniCustomer = (dni: string) => {
    if (dni === dniCustomer) {
      return true;
    } else {
      return false;
    }
  };

  React.useEffect(() => {
    getBookById();
  }, []);

  return (
    <div>
      <Grid container>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Nombres</Title>
          <label>{book?.idClient?.name}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Apellidos</Title>
          <label>{book?.idClient?.lastname}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Fecha de creación</Title>
          <label>{formatStartDate(book?.createdDate)}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Fecha de inicio</Title>
          <label>{formatStartDate(book?.startDate)}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Fecha de vencimiento</Title>
          <label>{formatStartDate(book?.endDate)}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Monto</Title>
          <label>s/{book?.amount}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Interes</Title>
          <label>{book?.interest}%</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Monto total</Title>
          <label>s/{book?.totalAmount}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Cuotas</Title>
          <label>{book?.totalFees}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Cuota diaria</Title>
          <label>s/{book?.dailyFee?.toFixed(2)}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Monto restante</Title>
          <label>
            s/
            {/* {Math.round(
              ((book?.missingAmount as number) + Number.EPSILON) * 100
            ) / 100} */}
            {book?.missingAmount}
          </label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Cobrador</Title>
          <label>{book?.idCollector?.name}</label>
        </Grid>
      </Grid>
      <br />
      <br />
      <div>
        <ContainerTable>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">N°</TableCell>
                <TableCell align="center">Fecha</TableCell>
                <TableCell align="center">Monto</TableCell>
                <TableCell align="center">Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {book?.days?.map((row, index) => (
                <>
                  <CustomRow
                    key={row.day}
                    color={
                      row.status === "NO PAGADO" ? "#FCFF75" : "transparent"
                    }
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {formatStartDate(row.day)}
                    </TableCell>
                    <TableCell align="center">
                      <b>s/{row.dayAmount}</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>{row.status}</b>
                    </TableCell>
                  </CustomRow>
                </>
              ))}
            </TableBody>
          </Table>
        </ContainerTable>
      </div>
      <ModalDni
        open={openModal}
        text="Ingrese DNI para visualizar cartilla"
        onClose={() => setOpenModal(!openModal)}
        actionSuccess={validateDniCustomer}
      />
    </div>
  );
};

export default TableBookCustomer;
