import React from "react";
import { useNavigate } from "react-router-dom";
import CardsRoads from "../components/containers/CardsRoads";
import { ButtonPrimary } from "../components/ui/Buttons";
import { Paragraph, Subtitle, Title } from "../components/ui/Texts";

const Roads = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Title>Rutas</Title>
      <Paragraph>Visualizador de rutas</Paragraph>
      <ButtonPrimary click={() => navigate("nueva-ruta")}>
        Crear ruta
      </ButtonPrimary>
      <Subtitle>Lista de rutas</Subtitle>
      <CardsRoads />
    </div>
  );
};

export default Roads;
