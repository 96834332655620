import React from "react";
import { useNavigate } from "react-router-dom";

const RedirectAuth = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate("/financiera");
  }, []);

  return <div></div>;
};

export default RedirectAuth;
