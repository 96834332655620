import {
  STATUS_CREATED,
  STATUS_SUCCESS,
} from "../../infraestructure/helpers/constants";
import { roadRepository } from "../../infraestructure/repositories/road.repository";
import { District } from "../models/District";
import { Road } from "../models/Road";
import { User } from "../models/User";

const saveDistrictsSelecteds = (districts: District[], district: District) => {
  const isExist = districts.some((item) => item._id === district._id);
  return isExist;
};

const deleteDistrictSelected = (districts: District[], district: District) => {
  const newDistricts = districts.filter((item) => item._id !== district._id);
  return newDistricts;
};

const saveCollectorsSelecteds = (collectors: User[], collector: User) => {
  const isExist = collectors.some((item) => item._id === collector._id);
  return isExist;
};

const deleteCollectorSelected = (collectors: User[], collector: User) => {
  const newCollectors = collectors.filter((item) => item._id !== collector._id);
  return newCollectors;
};

const createRoad = async (data: Road, token: string) => {
  const road = await roadRepository.postCreateRoad(data, token);
  if (road.status === STATUS_CREATED || road.status === STATUS_SUCCESS) {
    return true;
  } else {
    return false;
  }
};

const getAllRoads = async (token: string) => {
  const roads = await roadRepository.getAllRoads(token);
  if (roads.status === STATUS_CREATED || roads.status === STATUS_SUCCESS) {
    return roads.data;
  } else {
    return [];
  }
};

const getRoadById = async (id: string, token: string) => {
  const road = await roadRepository.getRoadById(id, token);
  if (road.status === STATUS_CREATED || road.status === STATUS_SUCCESS) {
    return road.data;
  }
};

export const roadService = {
  saveDistrictsSelecteds,
  deleteDistrictSelected,
  saveCollectorsSelecteds,
  deleteCollectorSelected,
  createRoad,
  getAllRoads,
  getRoadById,
};
