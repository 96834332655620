import React from "react";
import CardsBooks from "../components/containers/CardsBooks";
import { Paragraph, Subtitle, Title } from "../components/ui/Texts";

const Biweekly = () => {
  const idType: any = process.env.REACT_APP_ID_BIWEEKLY;

  return (
    <div>
      <Title>Pagos Quincenales</Title>
      <Paragraph>Visualizador de cartillas de pagos quincenales</Paragraph>
      <Subtitle>Lista de cartillas</Subtitle>
      <CardsBooks idType={idType} type="pago-quincenal" />
    </div>
  );
};

export default Biweekly;
