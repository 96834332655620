import styled from "styled-components";

export const ContainerButtons = styled.div`
  display: flex;
  margin: 10px 0px;
  flex-wrap: wrap;
  span {
    margin: 0px 20px;
  }
`;

export const ContainerUpdated = styled.div`
  margin: 30px 0px;
`;

export const InfoName = styled.p`
  margin: 10px 0px;
  font-weight: 400;
  span {
    font-weight: 700;
  }
`;

export const Form = styled.div`
  margin: 20px 0px;
`;
