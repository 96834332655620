import React from "react";
import { Grid } from "@mui/material";
import { profileService } from "../../../../domain/services/profile.service";
import { Profile } from "../../../../domain/models/Profile";
import CardProfile from "../../elements/CardProfile";
import { getToken } from "../../../helpers/constants";

const CardsProfiles = () => {
  const [profiles, setProfiles] = React.useState<Profile[]>([]);

  const getAllProfiles = async () => {
    const data = await profileService.getAllProfiles(getToken());
    setProfiles(data);
  };

  React.useEffect(() => {
    getAllProfiles();
  }, []);

  return (
    <div>
      <Grid container spacing={3} style={{ marginTop: 10 }}>
        {profiles.map((item, index) => {
          return (
            <Grid item xs={12} md={3} sm={6} key={index}>
              <CardProfile {...item} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default CardsProfiles;
