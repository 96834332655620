import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0px;
  p {
    font-weight: 700;
    font-size: 18px;
  }
`;

export const Information = styled.div`
  margin: 5px 0px;
  font-size: 35px;
  font-weight: 900;
`;

export const Name = styled.p`
  font-size: 20px;
  font-weight: 700;
`;

export const Card = styled.div`
  padding: 15px;
  border: 1px solid ${(props) => props.theme.colors.primary};
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2);
`;
