import { User } from "../../domain/models/User";
import { http } from "../http/http";

const URL = process.env.REACT_APP_URL;
const idCollector = process.env.REACT_APP_ID_COLLECTOR;

export const userRepository = {
  postUserCollector: async (body: User, token: string) => {
    const response = await http.post(
      `${URL}/users`,
      JSON.stringify(body),
      token
    );
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  getUserCollector: async (token: string) => {
    const response = await http.get(
      `${URL}/users/${idCollector}/profile`,
      token
    );
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  getIdUser: async (id: string, token: string) => {
    const response = await http.get(`${URL}/users/${id}`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  putUserCollector: async (body: User, id: string, token: string) => {
    const response = await http.put(
      `${URL}/users/${id}`,
      JSON.stringify(body),
      token
    );
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
};
