import React from "react";
import FormCustomer from "../components/elements/FormCustomer";
import { Paragraph, Subtitle, Title } from "../components/ui/Texts";

const NewCustomer = () => {
  return (
    <div>
      <Title>Creación de Cliente</Title>
      <Paragraph>Registro de cliente nuevo</Paragraph>
      <Subtitle>Información de cliente</Subtitle>
      <FormCustomer />
    </div>
  );
};

export default NewCustomer;
