import { Button } from "@mui/material";
import React from "react";
import { myTheme } from "../../../assets/styles/my-theme";
import { ContainerInput, Input, Label, LabelError } from "./style";

interface IInputProps {
  label: string;
  value: string | number;
  name: string;
  handleChange: any;
  maxLenght?: number;
  error?: boolean;
  errorText?: string;
  disabled?: boolean;
}

interface IInputFileProps {
  label: string;
  value: string | number;
  nameForm: string;
  helperText: string;
  onChangePhoto: any;
}

export const InputText: React.FC<IInputProps> = ({
  label,
  handleChange,
  name,
  value,
  maxLenght = 100,
  error = false,
  errorText = "",
  disabled = false,
}) => {
  return (
    <ContainerInput>
      <Label error={error}>{label}</Label>
      <Input
        name={name}
        value={value}
        type="text"
        onChange={handleChange}
        error={error}
        autoComplete="off"
        maxLength={maxLenght}
        disabled={disabled}
      />
      {error && <LabelError>{errorText}</LabelError>}
    </ContainerInput>
  );
};

export const InputPassword: React.FC<IInputProps> = ({
  label,
  handleChange,
  name,
  value,
  maxLenght = 100,
  error = false,
  errorText = "",
}) => {
  return (
    <ContainerInput>
      <Label error={error}>{label}</Label>
      <Input
        name={name}
        value={value}
        type="password"
        onChange={handleChange}
        error={error}
        autoComplete="off"
        maxLength={maxLenght}
      />
      {error && <LabelError>{errorText}</LabelError>}
    </ContainerInput>
  );
};

export const InputNumber: React.FC<IInputProps> = ({
  label,
  handleChange,
  name,
  value,
  maxLenght = 100,
  error = false,
  errorText = "",
  disabled = false,
}) => {
  const onlyNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/[^0-9,.]/g, "");
  };

  return (
    <ContainerInput>
      <Label error={error}>{label}</Label>
      <Input
        name={name}
        value={value}
        type="text"
        onChange={handleChange}
        maxLength={maxLenght}
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => onlyNumbers(e)}
        autoComplete="off"
        error={error}
        disabled={disabled}
      />
      {error && <LabelError>{errorText}</LabelError>}
    </ContainerInput>
  );
};

export const InputName: React.FC<IInputProps> = ({
  label,
  handleChange,
  name,
  value,
  maxLenght = 100,
  error = false,
  errorText = "",
  disabled = false,
}) => {
  const onlyLetters = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/[^a-zA-ZñÑ\s]/g, "");
  };

  return (
    <ContainerInput>
      <Label error={error}>{label}</Label>
      <Input
        name={name}
        value={value}
        onChange={handleChange}
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => onlyLetters(e)}
        maxLength={maxLenght}
        autoComplete="off"
        error={error}
        disabled={disabled}
      />
      {error && <LabelError>{errorText}</LabelError>}
    </ContainerInput>
  );
};

export const InputFile: React.FC<IInputFileProps> = ({
  label,
  nameForm,
  value,
  helperText,
  onChangePhoto,
}) => {
  const [failedFile, setFailedFile] = React.useState(false);

  const onLoadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: any = e.target.files;
    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", "xu3bmt9b");
    const res = await fetch(
      "https://api.cloudinary.com/v1_1/dk014ac3d/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    const file = await res.json();
    if (file.secure_url) {
      setFailedFile(false);
      onChangePhoto(nameForm, file.secure_url);
    } else {
      setFailedFile(true);
    }
  };

  return (
    <ContainerInput>
      <Label style={{ display: "block" }}>{label}</Label>
      <Button
        variant="contained"
        component="label"
        style={{
          background: myTheme.colors.default,
          borderRadius: "5px",
          color: myTheme.colors.primary,
          padding: "8px 3%",
          outline: "none",
          cursor: "pointer",
          fontSize: "18px",
          fontWeight: 500,
          textTransform: "initial",
          border: `1px solid ${myTheme.colors.primary}`,
          width: "100%",
        }}
      >
        <p>Adjunta aquí el documento</p>
        <input
          type="file"
          hidden
          id={nameForm}
          onChange={onLoadImage}
          accept="image/png, image/gif, image/jpeg"
        />
      </Button>
      {failedFile && <p>No se pudo cargar la imagen</p>}
    </ContainerInput>
  );
};

export const InputDate: React.FC<IInputProps> = ({
  label,
  handleChange,
  name,
  value,
  maxLenght = 100,
  error = false,
  errorText = "",
}) => {
  return (
    <ContainerInput>
      <Label error={error}>{label}</Label>
      <Input
        type="date"
        name={name}
        value={value}
        onChange={handleChange}
        // min={formatToday()}
        error={error}
      />
      {error && <LabelError>{errorText}</LabelError>}
    </ContainerInput>
  );
};

export const InputDateToday: React.FC<IInputProps> = ({
  label,
  handleChange,
  name,
  value,
}) => {
  return (
    <ContainerInput>
      <Label>{label}</Label>
      <Input
        type="date"
        name={name}
        value={value}
        onChange={handleChange}
        // max={formatToday()}
      />
    </ContainerInput>
  );
};
