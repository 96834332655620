import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
   *{
       margin: 0;
       padding: 0;
       outline:0;
       box-sizing:border-box;
       font-family: 'Raleway', sans-serif !important; 
   }
   #root{
       margin: 0 auto;
   }
`;
