import React from "react";
import CardsBooks from "../components/containers/CardsBooks";
import { Paragraph, Subtitle, Title } from "../components/ui/Texts";

const Daily = () => {
  const idType: any = process.env.REACT_APP_ID_DAILY;

  return (
    <div>
      <Title>Pagos Diarios</Title>
      <Paragraph>Visualizador de cartillas de pagos diarios</Paragraph>
      <Subtitle>Lista de cartillas</Subtitle>
      <CardsBooks idType={idType} type="pago-diario" />
    </div>
  );
};

export default Daily;
