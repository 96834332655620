import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Customer } from "../../../../domain/models/Customer";
import { District } from "../../../../domain/models/District";
import { bookService } from "../../../../domain/services/book.service";
import { customerService } from "../../../../domain/services/customer.service";
import { districtService } from "../../../../domain/services/district.service";
import { getToken, getTypeUser, USER_ADMIN } from "../../../helpers/constants";
import { validatorCustomer } from "../../../helpers/validations/customer";
import { useForm } from "../../../hooks/useForm";
import { ButtonPrimary, ButtonSecondary } from "../../ui/Buttons";
import { InputFile, InputName, InputNumber, InputText } from "../../ui/Inputs";
import { ModalError, ModalSuccess } from "../../ui/Modals";
import Select from "../../ui/Select";
import CardBookCustomer from "../CardBookCustomer";
import { ContainerButtons, GridInput, ImageCustomer } from "./style";

interface IFormUpdateCustomerProps {
  id: string;
}

const FormUpdateCustomer: React.FC<IFormUpdateCustomerProps> = ({ id }) => {
  const navigate = useNavigate();

  const [booksCustomer, setBooksCustomer] = React.useState([]);
  const [infoCustomer, setInfoCustomer] = React.useState<Customer>({
    dni: "",
    name: "",
    lastname: "",
    district: "",
    location: "",
    idProfile: "62180cf6a845956fb7a2f238",
    alias: "",
    dniFront: "",
    dniBack: "",
    contrato: "",
    recibo: "",
  });

  const { form, onChangeError } = useForm({
    dniError: false,
    dniErrorText: "",
    nameError: false,
    nameErrorText: "",
    lastNameError: false,
    lastNameErrorText: "",
    districtError: false,
    districtErrortext: "",
    locationError: false,
    locationErrorText: "",
    aliasError: false,
    aliasErrorText: "",
  });

  const [openSuccess, setOpenSuccess] = React.useState<boolean>(false);
  const [openError, setOpenError] = React.useState<boolean>(false);
  const [openDeleteSuccess, setOpenDeleteSuccess] =
    React.useState<boolean>(false);
  const [openDeleteError, setOpenDeleteError] = React.useState<boolean>(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInfoCustomer({
      ...infoCustomer,
      [e.target.name]: e.target.value,
    });
  };

  const onChangePhoto = (name: any, link: string) => {
    setInfoCustomer({
      ...infoCustomer,
      [name]: link,
    });
  };

  const onChangeDistrict = (district: District) => {
    setInfoCustomer({
      ...infoCustomer,
      district: district.name,
    });
  };

  const [districts, setDistricts] = React.useState<District[]>([]);

  const getDistricts = async () => {
    const data = await districtService.getAllDistricts(getToken());
    setDistricts(data);
  };

  const getBooksByClient = async () => {
    const data = await bookService.getBooksByClient(id, getToken());
    setBooksCustomer(data);
  };

  const putCustomer = async () => {
    const validateCustomer = validatorCustomer(infoCustomer, form);
    if (validateCustomer) {
      onChangeError(validateCustomer);
    } else {
      const response = await customerService.putCustomer(
        infoCustomer,
        getToken()
      );
      if (response) {
        onChangeError({
          dniError: false,
          dniErrorText: "",
          nameError: false,
          nameErrorText: "",
          lastNameError: false,
          lastNameErrorText: "",
          districtError: false,
          districtErrortext: "",
          locationError: false,
          locationErrorText: "",
          aliasError: false,
          aliasErrorText: "",
        });
        setOpenSuccess(!openSuccess);
      } else {
        setOpenError(!openError);
      }
    }
  };

  const deleteIdCustomer = async () => {
    const response = await customerService.deleteIdCustomer(
      infoCustomer._id as string,
      getToken()
    );
    if (response) {
      setOpenDeleteSuccess(!openDeleteSuccess);
    } else {
      setOpenDeleteError(!openDeleteError);
    }
  };

  const getInfoIdCustomer = async () => {
    const customer = await customerService.getIdCustomer(id, getToken());
    setInfoCustomer({
      ...customer,
      idProfile: "62180cf6a845956fb7a2f238",
    });
  };

  React.useEffect(() => {
    getDistricts();
    getInfoIdCustomer();
    getBooksByClient();
  }, []);

  return (
    <div>
      <Grid container>
        <GridInput item xs={12} sm={6} md={4}>
          <InputNumber
            label="DNI"
            value={infoCustomer.dni}
            name="dni"
            handleChange={onChange}
            error={form.dniError}
            errorText={form.dniErrorText}
            maxLenght={8}
          />
        </GridInput>
        <GridInput item xs={12} sm={6} md={4}>
          <InputName
            label="Nombres"
            value={infoCustomer.name}
            name="name"
            handleChange={onChange}
            error={form.nameError}
            errorText={form.nameErrorText}
          />
        </GridInput>
        <GridInput item xs={12} sm={6} md={4}>
          <InputName
            label="Apellidos"
            value={infoCustomer.lastname}
            name="lastname"
            handleChange={onChange}
            error={form.lastNameError}
            errorText={form.lastNameErrorText}
          />
        </GridInput>
        <GridInput item xs={12} sm={6} md={4}>
          <InputText
            label="Cliente"
            value={infoCustomer.alias}
            name="alias"
            handleChange={onChange}
            error={form.aliasError}
            errorText={form.aliasErrorText}
          />
        </GridInput>
        <GridInput item xs={12} sm={12} md={8}></GridInput>
        <GridInput item xs={12} sm={6} md={4}>
          <Select
            data={districts}
            label="Distrito"
            onChange={onChangeDistrict}
            error={form.districtError}
            errorText={form.districtErrorText}
            optionValue={infoCustomer.district}
          />
        </GridInput>
        <GridInput item xs={12} sm={6} md={8}>
          <InputText
            label="Dirección"
            value={infoCustomer.location}
            name="location"
            handleChange={onChange}
            error={form.locationError}
            errorText={form.locationErrorText}
          />
        </GridInput>
        <GridInput item xs={12} sm={6} md={4}>
          <InputFile
            label="Foto de DNI (Adelante)"
            value=""
            nameForm="dniFront"
            helperText=""
            onChangePhoto={onChangePhoto}
          />
          <ImageCustomer src={infoCustomer.dniFront} />
        </GridInput>
        <GridInput item xs={12} sm={6} md={4}>
          <InputFile
            label="Foto de DNI (Atras)"
            value=""
            nameForm="dniBack"
            helperText=""
            onChangePhoto={onChangePhoto}
          />
          <ImageCustomer src={infoCustomer.dniBack} />
        </GridInput>
        <GridInput item xs={12} sm={6} md={4}>
          <InputFile
            label="Contrato"
            value=""
            nameForm="contrato"
            helperText=""
            onChangePhoto={onChangePhoto}
          />
          <ImageCustomer src={infoCustomer.contrato} />
        </GridInput>
        <GridInput item xs={12} sm={6} md={4}>
          <InputFile
            label="Recibo de servicios"
            value=""
            nameForm="recibo"
            helperText=""
            onChangePhoto={onChangePhoto}
          />
          <ImageCustomer src={infoCustomer.recibo} />
        </GridInput>
      </Grid>
      <ContainerButtons>
        <ButtonPrimary disabled={false} click={putCustomer}>
          Actualizar cliente
        </ButtonPrimary>
        <div />
        <ButtonSecondary click={() => navigate("/financiera/clientes")}>
          Cancelar
        </ButtonSecondary>
        <div />
        {getTypeUser() === USER_ADMIN && (
          <ButtonPrimary disabled={false} click={deleteIdCustomer}>
            Eliminar cliente
          </ButtonPrimary>
        )}
      </ContainerButtons>
      <Grid container spacing={3}>
        {booksCustomer.length > 0 &&
          booksCustomer.map((item, index) => {
            return (
              <Grid item xs={12} md={3} sm={6} key={index}>
                <CardBookCustomer book={item} typeBook={""} />
              </Grid>
            );
          })}
      </Grid>
      <ModalSuccess
        open={openSuccess}
        text="Se creo correctamente el cliente"
        actionSuccess={() => navigate("/financiera/clientes")}
        textSuccess="Ver Clientes"
      />
      <ModalError
        open={openError}
        text="Hubieron problemas al crear el cliente"
        actionSuccess={() => setOpenError(!openError)}
        textSuccess="Reintentar"
      />
      <ModalSuccess
        open={openDeleteSuccess}
        text="Se elimino correctamente el cliente"
        actionSuccess={() => navigate("/financiera/clientes")}
        textSuccess="Ver Clientes"
      />
      <ModalError
        open={openDeleteError}
        text="Hubieron problemas al eliminar el cliente"
        actionSuccess={() => setOpenDeleteError(!openDeleteError)}
        textSuccess="Reintentar"
      />
    </div>
  );
};

export default FormUpdateCustomer;
