import React from "react";
import { movementsService } from "../../../../domain/services/movements.service";
import {
  getPermissionUser,
  getToken,
  getTypeUser,
  getUser,
  USER_ADMIN,
  USER_COLLECTOR,
} from "../../../helpers/constants";
import { formatToday } from "../../../helpers/date";
import { ButtonPrimary } from "../../ui/Buttons";
import { ModalMovement } from "../../ui/Modals";
import { Content, Header } from "./style";

interface ICardMovementesProps {
  day: any;
}

const CardMovements: React.FC<ICardMovementesProps> = ({ day }) => {
  const [movements, setMovements] = React.useState<any[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const getMovementsByDay = async () => {
    const movements = await movementsService.getMovementsByDay(day, getToken());
    setMovements(movements);
  };

  const addMovements = (movement: any) => {
    setMovements([...movements, movement]);
  };

  React.useEffect(() => {
    getMovementsByDay();
  }, [day]);

  return (
    <div>
      {getTypeUser() === USER_ADMIN && movements.length > 0 && (
        <>
          <Header>
            <p style={{ flexGrow: 1, maxWidth: 150 }}>Motivo</p>
            <p style={{ marginRight: 10 }}>Monto</p>
            <p>Cobrador</p>
          </Header>
          {movements.map((item: any, index) => {
            return (
              <Content key={index}>
                <p style={{ flexGrow: 1, maxWidth: 150 }}>{item.reason}</p>
                <p style={{ marginRight: 40 }}>s/{item.amount}</p>
                <p>{item.idUser?.name}</p>
              </Content>
            );
          })}
        </>
      )}
      {getTypeUser() === USER_COLLECTOR &&
        movements.filter((item) => item.idUser?._id === getUser()._id).length >
          0 && (
          <>
            <Header>
              <p>Motivo</p>
              <p>Monto</p>
            </Header>
            {movements.map((item: any, index) => {
              return (
                <Content key={index}>
                  <p>{item.reason}</p>
                  <p>s/{item.amount}</p>
                </Content>
              );
            })}
          </>
        )}
      {getPermissionUser() === 1 && day === formatToday() && (
        <ButtonPrimary click={() => setOpenModal(!openModal)}>
          Agregar movimiento
        </ButtonPrimary>
      )}
      <ModalMovement
        open={openModal}
        onClose={() => setOpenModal(!openModal)}
        text="Crear observación"
        actionSuccess={addMovements}
      />
    </div>
  );
};

export default CardMovements;
