export const typesPermissions = [
  {
    name: "TODOS",
  },
  {
    name: "LEER",
  },
  {
    name: "EDITAR",
  },
  {
    name: "ACTUALIZAR",
  },
];
