const headersPublic = {
  "Content-Type": "application/json",
};

const headers = (token: string) => ({
  "Content-Type": "application/json",
  Authorization: token,
});

const getPublic = async (url: string) => {
  const response = await fetch(url, {
    method: "GET",
    headers: headersPublic,
  });
  return response;
};

const get = async (url: string, token: string) => {
  const response = await fetch(url, {
    method: "GET",
    headers: headers(token),
  });
  return response;
};

const getBody = async (url: string, body: any, token: string) => {
  const response = await fetch(url, {
    method: "GET",
    headers: headers(token),
    body,
  });
  return response;
};

const postPublic = async (url: string, body: any) => {
  const response = await fetch(url, {
    method: "POST",
    headers: headersPublic,
    body,
  });
  return response;
};

const post = async (url: string, body: any, token: string) => {
  const response = await fetch(url, {
    method: "POST",
    headers: headers(token),
    body,
  });
  return response;
};

const put = async (url: string, body: any, token: string) => {
  const response = await fetch(url, {
    method: "PUT",
    headers: headers(token),
    body,
  });
  return response;
};

const _delete = async (url: string, token: string) => {
  const response = await fetch(url, {
    method: "DELETE",
    headers: headers(token),
  });
  return response;
};

export const http = {
  get,
  post,
  put,
  delete: _delete,
  getPublic,
  postPublic,
  getBody,
};
