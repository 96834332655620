import { Grid } from "@mui/material";
import React from "react";
import CardCollector from "../../elements/CardCollector";
import { User } from "../../../../domain/models/User";
import { userService } from "../../../../domain/services/user.service";
import { getToken } from "../../../helpers/constants";

const CardsCollectors = () => {
  const [collectors, setCollectors] = React.useState<User[]>([]);

  const getCollectors = async () => {
    const data: User[] = await userService.getUsersCollectors(getToken());
    setCollectors(data);
  };

  React.useEffect(() => {
    getCollectors();
  }, []);

  return (
    <div>
      <Grid container spacing={3}>
        {collectors.map((item, index) => {
          return (
            <Grid item xs={12} md={3} sm={6} key={index}>
              <CardCollector {...item} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default CardsCollectors;
