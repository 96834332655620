import { http } from "../http/http";
import { AuthUser } from "../../domain/models/Auth";

const URL = process.env.REACT_APP_URL;

export const authRepository = {
  signIn: async (body: AuthUser) => {
    const response = await http.postPublic(`${URL}/auth`, JSON.stringify(body));
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
};
