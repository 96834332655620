import {
  STATUS_CREATED,
  STATUS_SUCCESS,
} from "../../infraestructure/helpers/constants";
import { userRepository } from "../../infraestructure/repositories/user.repository";
import { User } from "../models/User";

const getUsersCollectors = async (token: string) => {
  const collectors = await userRepository.getUserCollector(token);
  if (
    collectors.status === STATUS_CREATED ||
    collectors.status === STATUS_SUCCESS
  ) {
    return collectors.data;
  } else {
    return [];
  }
};

const postUserCollector = async (data: User, token: string) => {
  const collector = await userRepository.postUserCollector(data, token);
  if (
    collector.status === STATUS_CREATED ||
    collector.status === STATUS_SUCCESS
  ) {
    return true;
  } else {
    return false;
  }
};

const putUserCollector = async (data: User, id: string, token: string) => {
  const collector = await userRepository.putUserCollector(data, id, token);
  if (
    collector.status === STATUS_CREATED ||
    collector.status === STATUS_SUCCESS
  ) {
    return true;
  } else {
    return false;
  }
};

const getIdUser = async (id: string, token: string) => {
  const collector = await userRepository.getIdUser(id, token);
  if (
    collector.status === STATUS_CREATED ||
    collector.status === STATUS_SUCCESS
  ) {
    return collector.data;
  } else {
    return {};
  }
};

export const userService = {
  getUsersCollectors,
  postUserCollector,
  getIdUser,
  putUserCollector,
};
