import { Road } from "../../domain/models/Road";
import { http } from "../http/http";

const URL = process.env.REACT_APP_URL;

export const roadRepository = {
  getAllRoads: async (token: string) => {
    const response = await http.get(`${URL}/routes`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  getRoadById: async (id: string, token: string) => {
    const response = await http.get(`${URL}/routes/${id}`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  postCreateRoad: async (body: Road, token: string) => {
    const response = await http.post(
      `${URL}/routes`,
      JSON.stringify(body),
      token
    );
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
};
