import styled from "styled-components";

export const ContainerWhite = styled.div`
  background: #ffffff;
  width: 100%;
  height: 50vh;
`;

export const ContainerBlue = styled.div`
  background: ${(props) => props.theme.colors.primary};
  width: 100%;
  height: 50vh;
`;

export const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
`;
