import React from "react";
import { useParams } from "react-router-dom";
import FormUpdateCustomer from "../components/elements/FormUpdateCustomer";
import { Paragraph, Title } from "../components/ui/Texts";

const IdCustomer = () => {
  const { idCustomer } = useParams();

  return (
    <div>
      <Title>Actualizar Clientes</Title>
      <Paragraph>Aquí podra actualizar datos del cliente</Paragraph>
      <FormUpdateCustomer id={idCustomer as string} />
    </div>
  );
};

export default IdCustomer;
