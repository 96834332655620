import { CircularProgress } from "@mui/material";
import React from "react";
import { Subtitle } from "../Texts";
import { ContainerLoader, ContainerResponse } from "./style";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

interface ILoaderProps {
  isLoading: boolean;
  error: boolean;
}

const Loader: React.FC<ILoaderProps> = ({ isLoading, error }) => {
  return (
    <ContainerLoader>
      {isLoading && (
        <ContainerResponse>
          <CircularProgress color="primary" size={60} />
          <Subtitle>Obteniendo información...</Subtitle>
        </ContainerResponse>
      )}
      {error && (
        <ContainerResponse>
          <WarningAmberIcon color="error" sx={{ fontSize: 100 }} />
          <Subtitle>Ocurrio un error, actualize la página por favor</Subtitle>
        </ContainerResponse>
      )}
    </ContainerLoader>
  );
};

export default Loader;
