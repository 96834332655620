import { Grid } from "@mui/material";
import React from "react";
import { BookM } from "../../../../domain/models/Book";
import { bookService } from "../../../../domain/services/book.service";
import { roadService } from "../../../../domain/services/road.service";
import { getToken, getUser } from "../../../helpers/constants";
import { filterBooks } from "../../../helpers/constants/books";
import { useForm } from "../../../hooks/useForm";
import CardBook from "../../elements/CardBook";
import { ButtonPrimary } from "../../ui/Buttons";
import { InputText } from "../../ui/Inputs";
import Loader from "../../ui/Loader";
import Select from "../../ui/Select";

interface ICardsBooksProps {
  idType: string;
  type: string;
  expired?: boolean;
}

const CardsBooks: React.FC<ICardsBooksProps> = ({ idType, type, expired }) => {
  const [books, setBooks] = React.useState<BookM[]>([]);
  const [currentBooks, setCurrentBooks] = React.useState<BookM[]>([]);
  const [routes, setRoutes] = React.useState<BookM[]>([]);

  const [resetSelect, setResetSelect] = React.useState(false);

  const { form, onChange, onReset } = useForm({
    search: "",
  });

  const [loading, setLoading] = React.useState({
    show: true,
    isLoading: true,
    error: false,
  });

  const getBooksByType = async () => {
    if (expired) {
      const books = await bookService.getBooksByExpired(getToken(), getUser());
      setBooks(books);
      setCurrentBooks(books);
    } else {
      const books = await bookService.getBooksByType(
        idType,
        getToken(),
        getUser()
      );
      setBooks(books);
      setCurrentBooks(books);
    }
  };

  const getAllRoutes = async () => {
    const data = await roadService.getAllRoads(getToken());
    setRoutes(data);
  };

  const onChangeSelect = (item: any) => {
    if (item.name === "Mayor monto") {
      setBooks(
        [...books].sort((a: any, b: any) =>
          a.totalAmount > b.totalAmount ? -1 : 1
        )
      );
    } else {
      setBooks(
        [...books].sort((a: any, b: any) =>
          a.totalAmount < b.totalAmount ? -1 : 1
        )
      );
    }
  };

  const onChangeSelectRoutes = (itemRoute: any) => {
    setBooks(
      [...currentBooks].filter((item) => item?.idRoute?.name === itemRoute.name)
    );
  };

  const onResetSelect = () => {
    setResetSelect(!resetSelect);
  };

  const searchBook = () => {
    onResetSelect();
    let booksSearch = currentBooks.filter(
      (item) =>
        item?.idClient?.name?.toLowerCase().indexOf(form.search.toLowerCase()) >
          -1 ||
        item?.idClient?.lastname
          ?.toLowerCase()
          .indexOf(form.search.toLowerCase()) > -1 ||
        item?.idClient?.alias
          ?.toLowerCase()
          .indexOf(form.search.toLowerCase()) > -1 ||
        item?.idClient?.dni?.toLowerCase().indexOf(form.search.toLowerCase()) >
          -1 ||
        `${item?.idClient?.name} ${item?.idClient?.lastname}`
          ?.toLowerCase()
          .indexOf(form.search.toLowerCase()) > -1
    );
    setBooks(booksSearch);
    onResetSelect();
  };

  const resetFilters = () => {
    onResetSelect();
    setBooks(currentBooks);
    onReset();
    onResetSelect();
  };

  const getTotalInformation = async () => {
    try {
      await getBooksByType();
      await getAllRoutes();
      setLoading({
        show: false,
        isLoading: false,
        error: false,
      });
    } catch (err) {
      console.log("err", err);
      setLoading({
        show: true,
        isLoading: false,
        error: true,
      });
    }
  };

  React.useEffect(() => {
    getTotalInformation();
  }, []);

  React.useEffect(() => {
    if (form.search !== "") {
      searchBook();
    } else {
      onResetSelect();
      setBooks(currentBooks);
      onResetSelect();
    }
  }, [form]);

  return (
    <>
      {loading.show ? (
        <Loader error={loading.error} isLoading={loading.isLoading} />
      ) : (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <Select
                data={filterBooks}
                label="Filtrar por"
                onChange={onChangeSelect}
                reset={resetSelect}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Select
                data={routes}
                label="Rutas"
                onChange={onChangeSelectRoutes}
                reset={resetSelect}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <InputText
                label="Ingrese busqueda"
                name="search"
                value={form.search}
                handleChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <ButtonPrimary click={resetFilters}>Limpiar</ButtonPrimary>
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid container spacing={3}>
            {books.map((item, index) => {
              return (
                <Grid item xs={12} md={3} sm={6} key={index}>
                  <CardBook book={item} expired={expired as boolean} />
                </Grid>
              );
            })}
          </Grid>
        </div>
      )}
    </>
  );
};

export default CardsBooks;
