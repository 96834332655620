import React from "react";
import CardsBooks from "../components/containers/CardsBooks";
import { Paragraph, Subtitle, Title } from "../components/ui/Texts";

const Monthly = () => {
  const idType: any = process.env.REACT_APP_ID_MONTHLY;

  return (
    <div>
      <Title>Pagos Mensuales</Title>
      <Paragraph>Visualizador de cartillas de pagos mensuales</Paragraph>
      <Subtitle>Lista de cartillas</Subtitle>
      <CardsBooks idType={idType} type="pago-mensual" />
    </div>
  );
};

export default Monthly;
