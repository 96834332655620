import React from "react";
import FormRoad from "../components/elements/FormRoad";
import { Paragraph, Subtitle, Title } from "../components/ui/Texts";

const NewRoad = () => {
  return (
    <div>
      <Title>Creación de Ruta</Title>
      <Paragraph>Registro de rutas nuevos</Paragraph>
      <Subtitle>Información de ruta</Subtitle>
      <FormRoad />
    </div>
  );
};

export default NewRoad;
