import { permissionRepository } from "../../infraestructure/repositories/permission.repository";

const getPermissionByModule = async (id: string, token: string) => {
  const permissions = await permissionRepository.getPermissionByModule(
    id,
    token
  );
  return permissions;
};

const putPermissionAction = async (
  action: string,
  id: string | any,
  token: string
) => {
  const permission = await permissionRepository.putPermissionAction(
    { action },
    id,
    token
  );
  return permission;
};

export const permissionService = {
  getPermissionByModule,
  putPermissionAction,
};
