import { Grid } from "@mui/material";
import React from "react";
import { ButtonPrimary, ButtonSecondary } from "../../ui/Buttons";
import { InputDate, InputNumber, InputText } from "../../ui/Inputs";
import Select from "../../ui/Select";
import {
  ContainerButton,
  ContainerCustomer,
  Label,
  Total,
  Wrap,
} from "./style";
import { useForm } from "../../../hooks/useForm";
import { Customer } from "../../../../domain/models/Customer";
import { customerService } from "../../../../domain/services/customer.service";
import { getToken } from "../../../helpers/constants";
import SearchCustomers from "../SearchCustomers";
import {
  validatorBook,
  validatorSearchCustomer,
} from "../../../helpers/validations/book";
import { Book, TypeBook } from "../../../../domain/models/Book";
import { Road } from "../../../../domain/models/Road";
import { roadService } from "../../../../domain/services/road.service";
import { User } from "../../../../domain/models/User";
import { bookService } from "../../../../domain/services/book.service";
import { useNavigate } from "react-router-dom";
import { ModalError, ModalSuccess } from "../../ui/Modals";

const FormBook = () => {
  const navigate = useNavigate();

  const { form, onChange } = useForm({
    word: "",
  });

  const { form: formError, onChangeError } = useForm({
    wordError: false,
    wordErrorText: "",
  });

  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const [typeBooks, setTypeBooks] = React.useState<TypeBook[]>([]);
  const [infoCustomer, setInfoCustomer] = React.useState<Customer>();
  const [disabledButton, setDisabledButton] = React.useState<boolean>(false);
  const [roads, setRoads] = React.useState<Road[]>([]);
  const [collectors, setCollectors] = React.useState<User[]>([]);

  const [book, setBook] = React.useState<Book>({
    amount: "",
    interest: "",
    totalAmount: "",
    idType: "",
    totalFees: "",
    createdDate: "",
    startDate: "",
    idRoute: "",
    idClient: "",
    idCollector: "",
  });

  const { form: formBookError, onChangeError: onChangeBookError } = useForm({
    amountError: false,
    aoumntErrorText: "",
    interestError: false,
    interestErrorText: "",
    idTypeError: false,
    idTypeErrorText: "",
    totalFeesError: false,
    totalFeesErrorText: "",
    startDateError: false,
    startDateErrorText: "",
    idRouteError: false,
    idRouteErrorText: "",
    idCollectorError: false,
    idCollectorErrorText: "",
  });

  const [openSuccess, setOpenSuccess] = React.useState<boolean>(false);
  const [openError, setOpenError] = React.useState<boolean>(false);

  const onChangeBook = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBook({
      ...book,
      [e.target.name]: e.target.value,
    });
  };

  const searchCustomers = async () => {
    const validateSearch = validatorSearchCustomer(form.word, formError);
    if (validateSearch) {
      onChangeError(validateSearch);
    } else {
      const customers = await customerService.getSearchCustomers(
        form.word.trim(),
        getToken()
      );
      setCustomers(customers);
      onChangeError({
        wordError: false,
        wordErrorText: "",
      });
    }
  };

  const selectedCustomer = (item: Customer | any) => {
    setInfoCustomer(item);
    setBook({
      ...book,
      idClient: item._id,
    });
  };

  const onChangeRoad = (item: any) => {
    setBook({
      ...book,
      idRoute: item._id,
    });
  };

  const onChangeCollector = (item: any) => {
    setBook({
      ...book,
      idCollector: item._id,
    });
  };

  const onChangeTypeBook = (item: any) => {
    setBook({
      ...book,
      idType: item._id,
    });
  };

  const getAllRoads = async () => {
    const data = await roadService.getAllRoads(getToken());
    setRoads(data);
  };

  const getTypeBooks = async () => {
    const data = await bookService.getTypesBooks(getToken());
    setTypeBooks(data);
  };

  const getRoadById = async (id: string) => {
    const data = await roadService.getRoadById(id, getToken());
    if (data) {
      setCollectors(data.collectors);
    }
  };

  const createBook = async () => {
    setDisabledButton(true);
    const validateBook = validatorBook(book, formBookError);
    if (validateBook) {
      onChangeBookError(validateBook);
    } else {
      const response = await bookService.postCreateBook(book, getToken());
      if (response) {
        onChangeBookError({
          amountError: false,
          aoumntErrorText: "",
          interestError: false,
          interestErrorText: "",
          idTypeError: false,
          idTypeErrorText: "",
          totalFeesError: false,
          totalFeesErrorText: "",
          createdDateError: false,
          createdDateErrorText: "",
          startDateError: false,
          startDateErrorText: "",
          idRouteError: false,
          idRouteErrorText: "",
          idCollectorError: false,
          idCollectorErrorText: "",
        });
        setOpenSuccess(!openSuccess);
      } else {
        setOpenError(!openError);
      }
    }
    setDisabledButton(false);
  };

  React.useEffect(() => {
    getAllRoads();
    getTypeBooks();
  }, []);

  React.useEffect(() => {
    if (book.idRoute !== "") {
      getRoadById(book.idRoute);
    }
  }, [book.idRoute]);

  React.useEffect(() => {
    if (book.amount !== "" && book.interest !== "") {
      const totalAmount = bookService.calculateInterest(
        book.amount,
        book.interest
      );
      setBook({
        ...book,
        totalAmount,
      });
    } else {
      setBook({
        ...book,
        totalAmount: "",
      });
    }
  }, [book.amount, book.interest]);

  return (
    <div>
      <ContainerCustomer>
        <Wrap>
          <InputText
            handleChange={onChange}
            label="Buscar cliente"
            name="word"
            value={form.word}
            error={formError.wordError}
            errorText={formError.wordErrorText}
          />
        </Wrap>
        <ButtonPrimary click={searchCustomers}>Buscar</ButtonPrimary>
      </ContainerCustomer>
      <SearchCustomers
        data={customers}
        infoCustomer={infoCustomer}
        selectedCustomer={selectedCustomer}
      />
      {infoCustomer && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} sm={6}>
              <InputNumber
                handleChange={onChangeBook}
                label="Monto"
                name="amount"
                value={book.amount}
                error={formBookError.amountError}
                errorText={formBookError.amountErrorText}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <InputNumber
                handleChange={onChangeBook}
                label="Interes %"
                name="interest"
                value={book.interest}
                error={formBookError.interestError}
                errorText={formBookError.interestErrorText}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <Label>Monto total</Label>
              <Total>S/{Math.ceil(book.totalAmount as number)}</Total>
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <Select
                label="Tipo de cartilla"
                data={typeBooks}
                onChange={onChangeTypeBook}
                error={formBookError.idTypeError}
                errorText={formBookError.idTypeErrorText}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <InputNumber
                handleChange={onChangeBook}
                label="Cantidad de cuotas"
                name="totalFees"
                value={book.totalFees}
                error={formBookError.totalFeesError}
                errorText={formBookError.totalFeesErrorText}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}></Grid>
            <Grid item xs={12} md={4} sm={6}>
              <InputDate
                handleChange={onChangeBook}
                label="Fecha de creación"
                name="createdDate"
                value={book.createdDate}
                error={formBookError.createdDateError}
                errorText={formBookError.createdDateErrorText}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <InputDate
                handleChange={onChangeBook}
                label="Fecha de inicio"
                name="startDate"
                value={book.startDate}
                error={formBookError.startDateError}
                errorText={formBookError.startDateErrorText}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}></Grid>
            <Grid item xs={12} md={4} sm={6}>
              <Select
                label="Ruta"
                data={roads}
                onChange={onChangeRoad}
                error={formBookError.idRouteError}
                errorText={formBookError.idRouteErrorText}
              />
            </Grid>
            {book.idRoute !== "" && (
              <Grid item xs={12} md={4} sm={6}>
                <Select
                  label="Cobrador"
                  data={collectors}
                  onChange={onChangeCollector}
                  error={formBookError.idCollectorError}
                  errorText={formBookError.idCollectorErrorText}
                />
              </Grid>
            )}
          </Grid>
          <br />
          <ContainerButton>
            <ButtonPrimary click={createBook} disabled={disabledButton}>
              Crear cartilla
            </ButtonPrimary>
            <div />
            <ButtonSecondary click={() => navigate("/financiera")}>
              Cancelar
            </ButtonSecondary>
          </ContainerButton>
        </>
      )}
      <ModalSuccess
        open={openSuccess}
        text="Se creo correctamente la cartilla"
        actionSuccess={() => navigate("/financiera")}
        textSuccess="Ver Dashboard"
      />
      <ModalError
        open={openError}
        text="Hubieron problemas al crear la cartilla"
        actionSuccess={() => setOpenError(!openError)}
        textSuccess="Reintentar"
      />
    </div>
  );
};

export default FormBook;
