import Cookies from "js-cookie";
import { User } from "../../../domain/models/User";

export const getToken = (): string => {
  const token: any = Cookies.get("tokenFinanciera");
  return token;
};

export const getUser = () => {
  const data: any = Cookies.get("userFinanciera");
  const user: User = JSON.parse(data);
  return user;
};

export const getTypeUser = () => {
  const data: any = Cookies.get("userFinanciera");
  const user: User = JSON.parse(data);
  const typeUser = user.idProfile.name;
  return typeUser;
};

export const getPermissionUser = () => {
  const data: any = Cookies.get("userFinanciera");
  const user: User = JSON.parse(data);
  const permission = user.permission;
  return permission;
};

export const STATUS_SUCCESS = 200;
export const STATUS_CREATED = 201;

export const USER_ADMIN = "ADMIN";
export const USER_COLLECTOR = "COBRADOR";

export const PAYMENT_PENDING = "PENDIENTE";
export const PAYMENT_NOT_PAYED = "NO PAGADO";
export const PAYMENT_PAID_OUT = "PAGADO";

export const BOOK_PAID_OUT = "PAGADO";
export const BOOK_PENDING = "PENDIENTE";
export const BOOK_DEFEATED = "VENCIDO";
export const BOOK_REFINANCED = "REFINANCIADO";

export const BOOK_DAILY = "DIARIO";
export const BOOK_WEEKLY = "SEMANAL";
export const BOOK_BIWEEKLY = "QUINCENAL";
export const BOOK_MONTHLY = "MENSUAL";
