import styled from "styled-components";
import { styled as styledM } from "@mui/material/styles";
import { TableContainer, TableRow } from "@mui/material";

export const ContainerTable = styledM(TableContainer)(() => ({
  maxWidth: "100vw",
  "@media (max-width: 700px)": {
    maxWidth: "85vw",
  },
}));

export const ContainerLink = styled.button`
  font-size: 14px;
  margin: 15px 0px;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  label {
    font-weight: 700;
    margin-left: 5px;
    cursor: pointer;
  }
`;

export const Link = styled.label`
  font-size: 12px;
  font-weight: 700;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
`;

export const CustomRow = styledM(TableRow)(({ color }) => ({
  background: color,
}));
