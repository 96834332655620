import {
  STATUS_CREATED,
  STATUS_SUCCESS,
} from "../../infraestructure/helpers/constants";
import { moduleRepository } from "../../infraestructure/repositories/module.repository";

const getModulesByProfile = async (id: string, token: string) => {
  const modules = await moduleRepository.getModulesByProfile(id, token);
  if (modules.status === STATUS_CREATED || modules.status === STATUS_SUCCESS) {
    return modules.data;
  } else {
    return [];
  }
};

const getModuleById = async (id: string, token: string) => {
  const module = await moduleRepository.getModuleById(id, token);
  return module;
};

export const moduleService = {
  getModulesByProfile,
  getModuleById,
};
