import React from "react";
import ContainerReports from "../components/containers/ContainerReports";
import { Paragraph, Title } from "../components/ui/Texts";

const Reports = () => {
  return (
    <div>
      <Title>Reportes</Title>
      <Paragraph>
        Visualizador de conteo de dinero y movimientos por día
      </Paragraph>
      <ContainerReports />
    </div>
  );
};

export default Reports;
