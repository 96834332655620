import React from "react";
import { ContainerForm, Form, Title, ContainerButton } from "./style";
import { useNavigate } from "react-router-dom";
import { InputText, InputPassword } from "../../ui/Inputs";
import logo from "../../../assets/images/logo.png";
import { ButtonPrimary } from "../../ui/Buttons";
import { useForm } from "../../../hooks/useForm";
import { authService } from "../../../../domain/services/auth.service";
import { validatorAuth } from "../../../helpers/validations/auth";
import { ModalError } from "../../ui/Modals";

const FormLogin = () => {
  const navigate = useNavigate();

  const { form, onChange } = useForm({
    user: "",
    password: "",
  });

  const { form: formError, onChangeError } = useForm({
    userError: false,
    userErrorText: "",
    passwordError: false,
    passwordErrorText: "",
  });

  const [openError, setOpenError] = React.useState<boolean>(false);

  const signIn = async () => {
    const validateAuth = validatorAuth(form, formError);
    if (validateAuth) {
      onChangeError(validateAuth);
    } else {
      onChangeError({
        userError: false,
        userErrorText: "",
        passwordError: false,
        passwordErrorText: "",
      });
      const response = await authService.signInUser(form, navigate);
      if (response) {
        setOpenError(!openError);
      }
    }
  };

  return (
    <ContainerForm>
      <picture>
        <img src={logo} alt="logo" style={{ margin: "0px 0px 50px 0px" }} />
      </picture>
      <Form>
        <Title>Iniciar sesión</Title>
        <InputText
          name="user"
          value={form.user}
          label="Usuario"
          handleChange={onChange}
          error={formError.userError}
          errorText={formError.userErrorText}
        />
        <br />
        <InputPassword
          name="password"
          value={form.password}
          label="Contraseña"
          handleChange={onChange}
          error={formError.passwordError}
          errorText={formError.passwordErrorText}
        />
        <br />
        <ContainerButton>
          <ButtonPrimary disabled={false} click={signIn}>
            Ingresar
          </ButtonPrimary>
        </ContainerButton>
      </Form>
      <ModalError
        open={openError}
        text="Usuario o contraseña incorrecta"
        actionSuccess={() => setOpenError(!openError)}
        textSuccess="Reintentar"
      />
    </ContainerForm>
  );
};

export default FormLogin;
