import { http } from "../http/http";

const URL = process.env.REACT_APP_URL;

export const movementsRepository = {
  getMovementsByDay: async (daySelected: string, token: string) => {
    const response = await http.get(
      `${URL}/movements/registrationdate/${daySelected}`,
      token
    );
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  postMovement: async (body: any, token: string) => {
    const response = await http.post(
      `${URL}/movements`,
      JSON.stringify(body),
      token
    );
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
};
