import React from "react";
import { Dialog, IconButton } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import {
  ContainerButtons,
  ContainerClose,
  CustomDialog,
  TextModal,
} from "./style";
import { ButtonPrimary, ButtonSecondary } from "../Buttons";
import { InputDate, InputNumber, InputText } from "../Inputs";
import { bookService } from "../../../../domain/services/book.service";
import {
  getToken,
  getTypeUser,
  getUser,
  USER_ADMIN,
} from "../../../helpers/constants";
import { useForm } from "../../../hooks/useForm";
import {
  validatorDniCustomer,
  validatorMovement,
  validatorObservationBook,
  validatorPaymentBook,
  validatorUpdatePaymentBook,
} from "../../../helpers/validations/book";
import { movementsService } from "../../../../domain/services/movements.service";

interface IModalProps {
  open: boolean;
  text: string;
  actionSuccess?: any;
  textSuccess?: string;
  onClose?: any;
  // actionCancel: any;
  // textCancel: string;
}

interface IModalPaymentProps {
  open: boolean;
  text: string;
  numberFee: number;
  idBook: any | string;
  onClose: any;
  setBook: any;
  missingAmount?: number | string;
  totalAmount?: number;
  daySelected: string;
  setOpenModalPaymentError?: any;
}

export const ModalError: React.FC<IModalProps> = ({
  open,
  text,
  actionSuccess,
  textSuccess,
  // actionCancel,
  // textCancel,
}) => {
  return (
    <Dialog open={open}>
      <CustomDialog>
        <div>
          <WarningAmberIcon color="error" sx={{ fontSize: 100 }} />
        </div>
        <TextModal>{text}</TextModal>
        <ContainerButtons>
          <ButtonPrimary click={actionSuccess}>{textSuccess}</ButtonPrimary>
          {/* <ButtonSecondary click={actionCancel}>{textCancel}</ButtonSecondary> */}
        </ContainerButtons>
      </CustomDialog>
    </Dialog>
  );
};

export const ModalSuccess: React.FC<IModalProps> = ({
  open,
  text,
  actionSuccess,
  textSuccess,
  // actionCancel,
  // textCancel,
}) => {
  return (
    <Dialog open={open}>
      <CustomDialog>
        <div>
          <CheckCircleOutlineIcon color="success" sx={{ fontSize: 100 }} />
        </div>
        <TextModal>{text}</TextModal>
        <ContainerButtons>
          <ButtonPrimary click={actionSuccess}>{textSuccess}</ButtonPrimary>
          {/* <ButtonSecondary click={actionCancel}>{textCancel}</ButtonSecondary> */}
        </ContainerButtons>
      </CustomDialog>
    </Dialog>
  );
};

export const ModalPayment: React.FC<IModalPaymentProps> = ({
  open,
  text,
  numberFee,
  idBook,
  onClose,
  setBook,
  missingAmount,
  daySelected,
  setOpenModalPaymentError,
}) => {
  const [payment, setPayment] = React.useState<number | string>("");

  const { form, onChangeError } = useForm({
    paymentError: false,
    paymentErrorText: "",
  });

  const [dayM, monthM, yearM] = daySelected.split("/");
  const [day, setDay] = React.useState({
    paymentDay: `${yearM}-${monthM}-${dayM}`,
  });

  const onChangeDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDay({
      ...day,
      [e.target.name]: e.target.value,
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPayment(e.target.value);
  };

  const putAmountBook = async () => {
    const validatePayment = validatorPaymentBook(payment, missingAmount, form);
    if (validatePayment) {
      onChangeError(validatePayment);
    } else {
      try {
        const response = await bookService.putAmountBook(
          idBook,
          {
            fee: numberFee + 1,
            dayAmount: payment,
            idUser: getUser()._id,
            updateDate: day.paymentDay,
          },
          getToken()
        );
        if (response.status !== 400) {
          onClose();
          setBook(response.data);
          onChangeError({
            paymentError: false,
            paymentErrorText: "",
          });
        } else {
          onClose();
          onChangeError({
            paymentError: false,
            paymentErrorText: "",
          });
          setOpenModalPaymentError(true);
        }
      } catch {
        onClose();
        onChangeError({
          paymentError: false,
          paymentErrorText: "",
        });
        setOpenModalPaymentError(true);
      }
    }
  };

  React.useEffect(() => {
    const [dayM, monthM, yearM] = daySelected.split("/");
    setDay({
      paymentDay: `${yearM}-${monthM}-${dayM}`,
    });
  }, [daySelected]);

  return (
    <Dialog open={open}>
      <CustomDialog>
        <ContainerClose>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </ContainerClose>
        <TextModal>{text}</TextModal>
        {getTypeUser() === USER_ADMIN && (
          <InputDate
            label="Fecha"
            name="paymentDay"
            value={day.paymentDay as string}
            handleChange={onChangeDay}
          />
        )}
        <br />
        <InputNumber
          handleChange={onChange}
          label="Monto"
          name="payment"
          value={payment}
          error={form.paymentError}
          errorText={form.paymentErrorText}
        />
        <br />
        <ContainerButtons>
          <ButtonPrimary click={putAmountBook}>Registrar</ButtonPrimary>
          <ButtonSecondary click={onClose}>Cancelar</ButtonSecondary>
        </ContainerButtons>
      </CustomDialog>
    </Dialog>
  );
};

export const ModalUpdatePayment: React.FC<IModalPaymentProps> = ({
  open,
  text,
  numberFee,
  idBook,
  onClose,
  setBook,
  totalAmount,
  daySelected,
}) => {
  const [payment, setPayment] = React.useState<number | string>("");

  const { form, onChangeError } = useForm({
    paymentError: false,
    paymentErrorText: "",
  });
  const [dayM, monthM, yearM] = daySelected.split("/");
  const [day, setDay] = React.useState({
    paymentDay: `${yearM}-${monthM}-${dayM}`,
  });

  const onChangeDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDay({
      ...day,
      [e.target.name]: e.target.value,
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPayment(e.target.value);
  };

  const putAmountBook = async () => {
    const validatePayment = validatorUpdatePaymentBook(
      payment,
      totalAmount,
      form
    );
    if (validatePayment) {
      onChangeError(validatePayment);
    } else {
      const response = await bookService.putAmountNewBook(
        idBook,
        {
          fee: numberFee + 1,
          dayAmount: payment,
          idUser: getUser()._id,
          updateDate: day.paymentDay,
        },
        getToken()
      );
      onClose();
      setBook(response.data);
      onChangeError({
        paymentError: false,
        paymentErrorText: "",
      });
    }
  };

  React.useEffect(() => {
    const [dayM, monthM, yearM] = daySelected.split("/");
    setDay({
      paymentDay: `${yearM}-${monthM}-${dayM}`,
    });
  }, [daySelected]);

  return (
    <Dialog open={open}>
      <CustomDialog>
        <ContainerClose>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </ContainerClose>
        <TextModal>{text}</TextModal>
        <InputDate
          label="Fecha"
          name="paymentDay"
          value={day.paymentDay}
          handleChange={onChangeDay}
        />
        <br />
        <InputNumber
          handleChange={onChange}
          label="Monto"
          name="payment"
          value={payment}
          error={form.paymentError}
          errorText={form.paymentErrorText}
        />
        <br />
        <ContainerButtons>
          <ButtonPrimary click={putAmountBook}>Actualizar</ButtonPrimary>
          <ButtonSecondary click={onClose}>Cancelar</ButtonSecondary>
        </ContainerButtons>
      </CustomDialog>
    </Dialog>
  );
};

export const ModalObservation: React.FC<IModalPaymentProps> = ({
  open,
  text,
  numberFee,
  idBook,
  onClose,
  setBook,
}) => {
  const [observation, setObservation] = React.useState<string>("");

  const { form, onChangeError } = useForm({
    observationError: false,
    observationErrorText: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setObservation(e.target.value);
  };

  const postObservationBook = async () => {
    const validatePayment = validatorObservationBook(observation, form);
    if (validatePayment) {
      onChangeError(validatePayment);
    } else {
      const response = await bookService.putObservationBook(
        idBook,
        {
          fee: numberFee + 1,
          details: observation,
          idUser: getUser()._id,
        },
        getToken()
      );
      onClose();
      setBook(response.data);
      onChangeError({
        observationError: false,
        observationErrorText: "",
      });
    }
  };

  return (
    <Dialog open={open}>
      <CustomDialog>
        <ContainerClose>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </ContainerClose>
        <TextModal>{text}</TextModal>
        <br />
        <InputText
          handleChange={onChange}
          label="Observación"
          name="observation"
          value={observation}
          error={form.observationError}
          errorText={form.observationErrorText}
        />
        <br />
        <ContainerButtons>
          <ButtonPrimary click={postObservationBook}>Registrar</ButtonPrimary>
          <ButtonSecondary click={onClose}>Cancelar</ButtonSecondary>
        </ContainerButtons>
      </CustomDialog>
    </Dialog>
  );
};

export const ModalMovement: React.FC<IModalProps> = ({
  open,
  text,
  onClose,
  actionSuccess,
}) => {
  const { form: formMovement, onChange } = useForm({
    reason: "",
    amount: "",
  });

  const { form, onChangeError } = useForm({
    reasonError: false,
    reasonErrorText: "",
    amountError: false,
    amountErrorText: "",
  });

  const postMovement = async () => {
    const validateMovement = validatorMovement(formMovement, form);
    if (validateMovement) {
      onChangeError(validateMovement);
    } else {
      const response = await movementsService.postMovement(
        {
          ...formMovement,
          amount: Number(formMovement.amount),
          idUser: getUser()._id,
        },
        getToken()
      );
      actionSuccess(response);
      onClose();
      onChangeError({
        reasonError: false,
        reasonErrorText: "",
        amountError: false,
        amountErrorText: "",
      });
    }
  };

  return (
    <Dialog open={open}>
      <CustomDialog>
        <ContainerClose>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </ContainerClose>
        <TextModal>{text}</TextModal>
        <br />
        <InputText
          handleChange={onChange}
          label="Motivo"
          name="reason"
          value={formMovement.reason}
          error={form.reasonError}
          errorText={form.reasonErrorText}
        />
        <br />
        <InputNumber
          handleChange={onChange}
          label="Monto"
          name="amount"
          value={formMovement.amount}
          error={form.amountError}
          errorText={form.amountErrorText}
        />
        <ContainerButtons>
          <ButtonPrimary click={postMovement}>Registrar</ButtonPrimary>
          <ButtonSecondary click={onClose}>Cancelar</ButtonSecondary>
        </ContainerButtons>
      </CustomDialog>
    </Dialog>
  );
};

export const ModalDni: React.FC<IModalProps> = ({
  open,
  text,
  actionSuccess,
  onClose,
}) => {
  const { form: formDni, onChange } = useForm({
    dni: "",
  });

  const { form, onChangeError } = useForm({
    dniError: false,
    dniErrorText: "",
  });

  const validateDni = async () => {
    const validate = validatorDniCustomer(formDni.dni, form);
    if (validate) {
      onChangeError(validate);
    } else {
      const result = actionSuccess(formDni.dni);
      if (result) {
        onChangeError({
          dniError: false,
          dniErrorText: "",
        });
        onClose();
      } else {
        onChangeError({
          dniError: true,
          dniErrorText: "El dni ingresado no coincide con el del cliente",
        });
      }
    }
  };

  return (
    <Dialog
      open={open}
      BackdropProps={{
        style: {
          backgroundColor: "grey",
          boxShadow: "none",
        },
      }}
    >
      <CustomDialog>
        <TextModal>{text}</TextModal>
        <p>
          En caso de Carnet de extranjeria, Ej: 001043333, ingresar 01043333,
          sin el primero cero
        </p>
        <br />
        <InputText
          handleChange={onChange}
          label="DNI"
          name="dni"
          value={formDni.dni}
          error={form.dniError}
          errorText={form.dniErrorText}
        />
        <ContainerButtons>
          <ButtonPrimary click={validateDni}>Ingresar</ButtonPrimary>
        </ContainerButtons>
      </CustomDialog>
    </Dialog>
  );
};
