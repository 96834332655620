import React from "react";
import { useNavigate } from "react-router-dom";
import CardsCollectors from "../components/containers/CardsCollectors";
import { ButtonPrimary } from "../components/ui/Buttons";
import { Paragraph, Subtitle, Title } from "../components/ui/Texts";

const Collectors = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Title>Cobradores</Title>
      <Paragraph>Visualizador de cobradores</Paragraph>
      <ButtonPrimary click={() => navigate("nuevo-cobrador")}>
        Crear cobrador
      </ButtonPrimary>
      <Subtitle>Lista de cobradores</Subtitle>
      <CardsCollectors />
    </div>
  );
};

export default Collectors;
