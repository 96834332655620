import {
  STATUS_CREATED,
  STATUS_SUCCESS,
} from "../../infraestructure/helpers/constants";
import {
  formatDMY,
  replaceSeparatorDate,
} from "../../infraestructure/helpers/date";
import { movementsRepository } from "../../infraestructure/repositories/movements.repository";

const getMovementsByDay = async (data: any, token: string) => {
  const movements = await movementsRepository.getMovementsByDay(data, token);
  if (
    movements.status === STATUS_CREATED ||
    movements.status === STATUS_SUCCESS
  ) {
    return movements.data.filter(
      (item: any) =>
        formatDMY(item.registrationDate) ===
        formatDMY(replaceSeparatorDate(data))
    );
  } else {
    return [];
  }
};

const postMovement = async (data: any, token: string) => {
  const customer = await movementsRepository.postMovement(data, token);
  if (
    customer.status === STATUS_CREATED ||
    customer.status === STATUS_SUCCESS
  ) {
    return customer.data;
  }
};

export const movementsService = {
  getMovementsByDay,
  postMovement,
};
