import React from "react";
import TableBookCustomer from "../components/elements/TableBookCustomer";
import { Paragraph, Subtitle, Title } from "../components/ui/Texts";

const BookCustomer = () => {
  return (
    <div style={{ padding: 40 }}>
      <Title>Información de Cartilla</Title>
      <Paragraph>Visualizador de datos de la cartilla</Paragraph>
      <Subtitle>Información del pago</Subtitle>
      <TableBookCustomer />
    </div>
  );
};

export default BookCustomer;
