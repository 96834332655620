import React from "react";
import { useNavigate } from "react-router-dom";
import { BookM } from "../../../../domain/models/Book";
import { formatStartDate } from "../../../helpers/date";
import { Card, Information, Name } from "./style";

interface ICardBookProps {
  book: BookM;
  expired: boolean;
}

const CardBook: React.FC<ICardBookProps> = ({ book, expired }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (expired) {
      navigate(
        `/financiera/pago-${book.idType.name.toLowerCase()}/${book._id}`
      );
    } else {
      navigate(book._id);
    }
  };

  return (
    <Card onClick={handleNavigate} status={book.status}>
      <Name>
        {book.idClient?.name} {book.idClient?.lastname}
      </Name>
      <p>{book.idClient?.alias}</p>
      <Information>
        <span>Monto total : </span> s/{book.totalAmount}
      </Information>
      <Information>
        <span>Cuotas : </span> {book.totalFees}
      </Information>
      <Information>
        <span>Fecha de inicio : </span> {formatStartDate(book.startDate)}
      </Information>
    </Card>
  );
};

export default CardBook;
