import { Book } from "../../../domain/models/Book";

export const validatorSearchCustomer = (word: string, error: any) => {
  let { wordError, wordErrorText } = error;

  if (word === "") {
    wordError = true;
    wordErrorText = "Ingrese busqueda";
  } else {
    wordError = false;
    wordErrorText = "";
  }

  if (wordError) {
    return { wordError, wordErrorText };
  } else {
    return false;
  }
};

export const validatorBook = (book: Book, error: any) => {
  let {
    amountError,
    aoumntErrorText,
    interestError,
    interestErrorText,
    idTypeError,
    idTypeErrorText,
    totalFeesError,
    totalFeesErrorText,
    startDateError,
    startDateErrorText,
    idRouteError,
    idRouteErrorText,
    idCollectorError,
    idCollectorErrorText,
    createdDateError,
    createdDateErrorText,
  } = error;
  const {
    amount,
    idCollector,
    idRoute,
    idType,
    interest,
    startDate,
    totalFees,
    createdDate,
  } = book;

  if (amount === "") {
    amountError = true;
    aoumntErrorText = "Ingrese monto";
  } else {
    amountError = false;
    aoumntErrorText = "";
  }

  if (interest === "") {
    interestError = true;
    interestErrorText = "Ingrese interes";
  } else {
    interestError = false;
    interestErrorText = "";
  }

  if (idType === "") {
    idTypeError = true;
    idTypeErrorText = "Ingrese tipo de cartilla";
  } else {
    idTypeError = false;
    idTypeErrorText = "";
  }

  if (totalFees === "") {
    totalFeesError = true;
    totalFeesErrorText = "Ingrese cantidad de cuotas";
  } else {
    totalFeesError = false;
    totalFeesErrorText = "";
  }

  if (createdDate === "") {
    createdDateError = true;
    createdDateErrorText = "Ingrese fecha de creación";
  } else {
    createdDateError = false;
    createdDateErrorText = "";
  }

  if (startDate === "") {
    startDateError = true;
    startDateErrorText = "Ingrese fecha de incio";
  } else {
    startDateError = false;
    startDateErrorText = "";
  }

  if (idRoute === "") {
    idRouteError = true;
    idRouteErrorText = "Ingrese ruta";
  } else {
    idRouteError = false;
    idRouteErrorText = "";
  }

  if (idCollector === "") {
    idCollectorError = true;
    idCollectorErrorText = "Ingrese cobrador";
  } else {
    idCollectorError = false;
    idCollectorErrorText = "";
  }

  if (
    amountError ||
    interestError ||
    idTypeError ||
    totalFeesError ||
    createdDateError ||
    startDateError ||
    idRouteError ||
    idCollectorError ||
    (amountError &&
      interestError &&
      idTypeError &&
      totalFeesError &&
      createdDateError &&
      startDateError &&
      idRouteError &&
      idCollectorError)
  ) {
    return {
      amountError,
      aoumntErrorText,
      interestError,
      interestErrorText,
      idTypeError,
      idTypeErrorText,
      totalFeesError,
      totalFeesErrorText,
      startDateError,
      startDateErrorText,
      idRouteError,
      idRouteErrorText,
      idCollectorError,
      idCollectorErrorText,
      createdDateError,
      createdDateErrorText,
    };
  } else {
    return false;
  }
};

export const validatorPaymentBook = (
  payment: any,
  missingAmount: any,
  error: any
) => {
  let { paymentError, paymentErrorText } = error;

  if (payment === "" || parseFloat(payment) === 0) {
    paymentError = true;
    paymentErrorText = "Ingrese monto";
  } else if (parseFloat(payment) > parseFloat(missingAmount)) {
    paymentError = true;
    paymentErrorText = "El monto ingresado es mayor al monto restante";
  } else {
    paymentError = false;
    paymentErrorText = "";
  }

  if (paymentError) {
    return { paymentError, paymentErrorText };
  } else {
    return false;
  }
};

export const validatorUpdatePaymentBook = (
  payment: any,
  missingAmount: any,
  error: any
) => {
  let { paymentError, paymentErrorText } = error;

  if (payment === "") {
    paymentError = true;
    paymentErrorText = "Ingrese monto";
  } else {
    paymentError = false;
    paymentErrorText = "";
  }

  if (paymentError) {
    return { paymentError, paymentErrorText };
  } else {
    return false;
  }
};

export const validatorDayExpirationBook = (day: any, error: any) => {
  let { dayError, dayErrorText } = error;

  if (day === "" || parseFloat(day) === 0) {
    dayError = true;
    dayErrorText = "Ingrese cantidad de días";
  } else {
    dayError = false;
    dayErrorText = "";
  }

  if (dayError) {
    return { dayError, dayErrorText };
  } else {
    return false;
  }
};

export const validatorObservationBook = (observation: string, error: any) => {
  let { observationError, observationErrorText } = error;

  if (observation === "") {
    observationError = true;
    observationErrorText = "Ingrese observación";
  } else {
    observationError = false;
    observationErrorText = "";
  }

  if (observationError) {
    return { observationError, observationErrorText };
  } else {
    return false;
  }
};

export const validatorDniCustomer = (dni: string, error: any) => {
  let { dniError, dniErrorText } = error;

  if (dni === "" || dni.length !== 8) {
    dniError = true;
    dniErrorText = "Ingrese dni correctamente";
  } else {
    dniError = false;
    dniErrorText = "";
  }

  if (dniError) {
    return { dniError, dniErrorText };
  } else {
    return false;
  }
};

export const validatorMovement = (movement: any, error: any) => {
  const { reason, amount } = movement;
  let { reasonError, reasonErrorText, amountError, amountErrorText } = error;

  if (reason === "") {
    reasonError = true;
    reasonErrorText = "Ingrese motivo";
  } else {
    reasonError = false;
    reasonErrorText = "";
  }

  if (amount === "" || Number(amount) === 0) {
    amountError = true;
    amountErrorText = "Ingrese monto";
  } else {
    amountError = false;
    amountErrorText = "";
  }

  if (reasonError || amountError || (reasonError && amountError)) {
    return { reasonError, reasonErrorText, amountError, amountErrorText };
  } else {
    return false;
  }
};
