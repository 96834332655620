import { Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { Module } from "../../../../domain/models/Module";
import { moduleService } from "../../../../domain/services/module.service";
import { getToken } from "../../../helpers/constants";
import CardModule from "../../elements/CardModule";

const CardsModules = () => {
  let params = useParams();

  const idProfile = params.idProfile as string;

  const [modules, setModules] = React.useState<Module[]>([]);

  const getModulesByProfile = async () => {
    const data = await moduleService.getModulesByProfile(idProfile, getToken());
    setModules(data);
  };

  React.useEffect(() => {
    getModulesByProfile();
  }, []);

  return (
    <div>
      <Grid container spacing={3} style={{ marginTop: 10 }}>
        {modules.map((item, index) => {
          return (
            <Grid item xs={12} md={3} sm={6} key={index}>
              <CardModule {...item} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default CardsModules;
