import {
  STATUS_CREATED,
  STATUS_SUCCESS,
} from "../../infraestructure/helpers/constants";
import { districtRepository } from "../../infraestructure/repositories/district.repository";

const getAllDistricts = async (token: string) => {
  const districts = await districtRepository.getAllDistricts(token);
  if (
    districts.status === STATUS_CREATED ||
    districts.status === STATUS_SUCCESS
  ) {
    return districts.data;
  } else {
    return [];
  }
};

export const districtService = {
  getAllDistricts,
};
