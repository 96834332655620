import { Grid } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BookM } from "../../../../domain/models/Book";
import { bookService } from "../../../../domain/services/book.service";
import {
  BOOK_PAID_OUT,
  BOOK_REFINANCED,
  getPermissionUser,
  getToken,
  getTypeUser,
  USER_ADMIN,
  USER_COLLECTOR,
} from "../../../helpers/constants";
import { ContainerLink, ContainerTable, CustomRow, Title } from "./style";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ButtonPrimary } from "../../ui/Buttons";
import { currentDay, formatStartDate } from "../../../helpers/date";
import {
  ModalError,
  ModalObservation,
  ModalPayment,
  ModalSuccess,
  ModalUpdatePayment,
} from "../../ui/Modals";
import { InputNumber } from "../../ui/Inputs";
import { useForm } from "../../../hooks/useForm";
import { validatorDayExpirationBook } from "../../../helpers/validations/book";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
interface ITableBookProps {
  type: string;
}

const TableBook: React.FC<ITableBookProps> = ({ type }) => {
  const navigate = useNavigate();
  let params = useParams();
  const idDaily = params.idDaily as string;

  const [book, setBook] = React.useState<BookM>();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [openModalUpdate, setOpenModalUpdate] = React.useState<boolean>(false);
  const [openModalObservation, setOpenModalObservation] =
    React.useState<boolean>(false);
  const [daySelected, setDaySelected] = React.useState<string>("");
  const [numberFee, setNumberFee] = React.useState<number>(0);
  const [numberDaysExpiration, setNumberDaysExpiration] = React.useState("");
  const [isNearExpiration, setIsNearExpiration] = React.useState(false);
  const [openModalDeleteError, setOpenModalDeleteError] =
    React.useState<boolean>(false);
  const [openRefinancedSuccess, setOpenRefinancedSuccess] =
    React.useState<boolean>(false);
  const [openRefinancedError, setOpenRefinancedError] =
    React.useState<boolean>(false);
  const [openModalPaymentError, setOpenModalPaymentError] =
    React.useState<boolean>(false);

  const { form, onChangeError } = useForm({
    dayError: false,
    dayErrorText: "",
  });

  const getBookById = async () => {
    const book = await bookService.getBookById(idDaily, getToken());
    setIsNearExpiration(true);
    setBook(book.data);
  };

  const onChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumberDaysExpiration(e.target.value);
  };

  const onChangeModal = (day: string, fee: number) => {
    setOpenModal(!openModal);
    setDaySelected(day);
    setNumberFee(fee);
  };

  const onChangeUpdateModal = (day: string, fee: number) => {
    setOpenModalUpdate(!openModalUpdate);
    setDaySelected(day);
    setNumberFee(fee);
  };

  const onChangeObservationModal = (day: string, fee: number) => {
    setOpenModalObservation(!openModalObservation);
    setDaySelected(day);
    setNumberFee(fee);
  };

  const putFinishBook = async () => {
    const book = await bookService.putFinishBook(idDaily, {}, getToken());
    if (book) {
      setOpenRefinancedSuccess(true);
    } else {
      setOpenRefinancedError(true);
    }
  };

  const putArrayDaysBook = async () => {
    const validateDays = validatorDayExpirationBook(numberDaysExpiration, form);
    if (validateDays) {
      onChangeError(validateDays);
    } else {
      const book: any = await bookService.putArrayDaysBooks(
        idDaily,
        {
          totalDays: parseInt(numberDaysExpiration),
        },
        getToken()
      );
      setBook(book.data.bookUpdated);
      setNumberDaysExpiration("");
      onChangeError({
        dayError: false,
        dayErrorText: "",
      });
    }
  };

  const deleteBookById = async () => {
    const result = await bookService.deleteBookById(
      navigate,
      idDaily,
      getToken()
    );
    if (result) setOpenModalDeleteError(true);
  };

  React.useEffect(() => {
    getBookById();
  }, []);

  return (
    <div>
      <Grid container>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Nombres</Title>
          <label>{book?.idClient?.name}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Apellidos</Title>
          <label>{book?.idClient?.lastname}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Cliente</Title>
          <label>{book?.idClient?.alias}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Fecha de creación</Title>
          <label>{formatStartDate(book?.createdDate)}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Fecha de inicio</Title>
          <label>{formatStartDate(book?.startDate)}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Fecha de vencimiento</Title>
          <label>{formatStartDate(book?.days[book?.totalFees - 1].day)}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Monto</Title>
          <label>s/{book?.amount}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Interes</Title>
          <label>{book?.interest}%</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Monto total</Title>
          <label>s/{book?.totalAmount}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Cuotas</Title>
          <label>{book?.totalFees}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Cuota diaria</Title>
          <label>{book?.dailyFee?.toFixed(2)}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Monto restante</Title>
          <label>s/{book?.missingAmount.toFixed(2)}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Ruta</Title>
          <label>{book?.idRoute?.name}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Title>Cobrador</Title>
          <label>{book?.idCollector?.name}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <CopyToClipboard
            text={`https://soluciones-financieras.net/cartillas/${book?._id}`}
          >
            <ContainerLink>
              <ContentCopyIcon /> <label>Copiar link</label>
            </ContainerLink>
          </CopyToClipboard>
        </Grid>
      </Grid>
      <br />
      <br />
      <div>
        <ContainerTable>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">N°</TableCell>
                <TableCell align="center">Fecha</TableCell>
                <TableCell align="center">Monto</TableCell>
                <TableCell align="center">Estado</TableCell>
                <TableCell align="center">Observaciones</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {book?.days?.map((row, index) => (
                <>
                  <CustomRow
                    key={row.day}
                    color={
                      row.status === "NO PAGADO" ? "#FCFF75" : "transparent"
                    }
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {formatStartDate(row.day)}
                    </TableCell>
                    <TableCell align="center">
                      <b>s/{row.dayAmount}</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>{row.status}</b>
                    </TableCell>
                    <TableCell align="center">
                      {row.details !== "" ? (
                        <p>{row.details}</p>
                      ) : getTypeUser() === USER_ADMIN ? (
                        <ButtonPrimary
                          click={() =>
                            onChangeObservationModal(
                              formatStartDate(row.day),
                              index
                            )
                          }
                        >
                          Crear
                        </ButtonPrimary>
                      ) : (
                        getPermissionUser() === 1 &&
                        book.status !== BOOK_PAID_OUT &&
                        book.status !== BOOK_REFINANCED &&
                        formatStartDate(row.day) === currentDay() && (
                          <ButtonPrimary
                            click={() =>
                              onChangeObservationModal(
                                formatStartDate(row.day),
                                index
                              )
                            }
                          >
                            Crear
                          </ButtonPrimary>
                        )
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {getTypeUser() === USER_COLLECTOR ? (
                        <>
                          {book.status !== BOOK_PAID_OUT &&
                            book.status !== BOOK_REFINANCED &&
                            row.status === "PENDIENTE" &&
                            formatStartDate(row.day) === currentDay() &&
                            getPermissionUser() === 1 && (
                              <ButtonPrimary
                                click={() =>
                                  onChangeModal(formatStartDate(row.day), index)
                                }
                              >
                                Pagar
                              </ButtonPrimary>
                            )}
                        </>
                      ) : (
                        <>
                          {row.dayAmount > 0 ? (
                            <ButtonPrimary
                              click={() =>
                                onChangeUpdateModal(
                                  formatStartDate(row.day),
                                  index
                                )
                              }
                            >
                              Actualizar
                            </ButtonPrimary>
                          ) : (
                            <ButtonPrimary
                              click={() =>
                                onChangeModal(formatStartDate(row.day), index)
                              }
                            >
                              Pagar
                            </ButtonPrimary>
                          )}
                        </>
                      )}
                    </TableCell>
                  </CustomRow>
                </>
              ))}
            </TableBody>
          </Table>
        </ContainerTable>
        <br />
        <br />
        {getTypeUser() === USER_ADMIN && (
          <div>
            <ButtonPrimary click={deleteBookById}>
              Eliminar cartilla
            </ButtonPrimary>
          </div>
        )}
        {book?.status !== BOOK_PAID_OUT &&
          book?.status !== BOOK_REFINANCED &&
          getTypeUser() === USER_ADMIN && (
            <div>
              <ButtonPrimary click={putFinishBook}>
                Terminar cartilla
              </ButtonPrimary>
              {isNearExpiration && (
                <>
                  <Title>Agregar fechas para vencimiento</Title>
                  <br />
                  <div>
                    <InputNumber
                      label="Numero de cuotas"
                      name="numberDaysExpiration"
                      value={numberDaysExpiration}
                      handleChange={onChangeNumber}
                      error={form.dayError}
                      errorText={form.dayErrorText}
                    />
                    <ButtonPrimary click={putArrayDaysBook}>
                      Agregar cuotas
                    </ButtonPrimary>
                  </div>
                </>
              )}
            </div>
          )}
      </div>
      <ModalPayment
        open={openModal}
        text={`Ingresar monto para el dia ${daySelected}`}
        numberFee={numberFee}
        idBook={book?._id}
        onClose={() => setOpenModal(!openModal)}
        setBook={setBook}
        missingAmount={book?.missingAmount.toFixed(2) as any}
        daySelected={daySelected}
        setOpenModalPaymentError={setOpenModalPaymentError}
      />
      <ModalUpdatePayment
        open={openModalUpdate}
        text={`Ingresar monto actualizado para el dia ${daySelected}`}
        numberFee={numberFee}
        idBook={book?._id}
        onClose={() => setOpenModalUpdate(!openModalUpdate)}
        setBook={setBook}
        totalAmount={book?.totalAmount}
        daySelected={daySelected}
      />
      <ModalObservation
        open={openModalObservation}
        text={`Ingresar observación para el dia ${daySelected}`}
        numberFee={numberFee}
        idBook={book?._id}
        onClose={() => setOpenModalObservation(!openModalObservation)}
        setBook={setBook}
        daySelected={daySelected}
      />
      <ModalError
        open={openModalDeleteError}
        text="Error al eliminar cartilla"
        actionSuccess={() => setOpenModalDeleteError(false)}
        textSuccess="Reintentar"
      />
      <ModalSuccess
        open={openRefinancedSuccess}
        text="Se refinancio correctamente la cartilla"
        actionSuccess={() => navigate(-1)}
        textSuccess="Reiniciar"
      />
      <ModalError
        open={openRefinancedError}
        text="Hubieron problemas al refinanciar la cartilla"
        actionSuccess={() => setOpenRefinancedError(!openRefinancedError)}
        textSuccess="Reintentar"
      />
      <ModalError
        open={openModalPaymentError}
        text="Hubieron problemas al pagar la cartilla"
        actionSuccess={() => setOpenModalPaymentError(!openModalPaymentError)}
        textSuccess="Reintentar"
      />
    </div>
  );
};

export default TableBook;
