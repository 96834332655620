import React from "react";
import CardsProfiles from "../components/containers/CardsProfiles";
import { Paragraph, Subtitle, Title } from "../components/ui/Texts";

const Permissions = () => {
  return (
    <div>
      <Title>Permisos</Title>
      <Paragraph>Visualizador de permisos</Paragraph>
      <Subtitle>Tipo de perfiles</Subtitle>
      <CardsProfiles />
    </div>
  );
};

export default Permissions;
