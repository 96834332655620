import React from "react";
import { useParams } from "react-router-dom";
import { Permission } from "../../../../domain/models/Permission";
import { permissionService } from "../../../../domain/services/permission.service";
import { getToken } from "../../../helpers/constants";
import { typesPermissions } from "../../../helpers/constants/permission";
import { ButtonPrimary, ButtonSecondary } from "../../ui/Buttons";
import Select from "../../ui/Select";
import { ContainerButtons, ContainerUpdated, Form, InfoName } from "./style";

const FormPermission = () => {
  let params: any = useParams();
  const [infoPermission, setInfoPermission] = React.useState<Permission>();
  const [updated, setUpdated] = React.useState<boolean>(false);
  const [actionPermission, setActionPermission] = React.useState<string>("");

  const changeUpdated = () => setUpdated(!updated);

  const getInfoPermission = async () => {
    const data = await permissionService.getPermissionByModule(
      params.idModule,
      getToken()
    );
    setInfoPermission(data.data[0]);
    setActionPermission(data.data[0].name);
  };

  const changeAction = (action: any) => setActionPermission(action.name);

  const updatePermissionAction = async () => {
    const resultPermission: any = await permissionService.putPermissionAction(
      actionPermission,
      infoPermission?._id,
      getToken()
    );
    setInfoPermission(resultPermission.data);
    changeUpdated();
  };

  React.useEffect(() => {
    getInfoPermission();
  }, []);

  return (
    <Form>
      <InfoName>
        Nombre de Módulo: <span>{infoPermission?.idModule?.name}</span>
      </InfoName>
      <InfoName>
        Tipo de Perfil: <span>{infoPermission?.idProfile?.name}</span>
      </InfoName>
      {!updated ? (
        <>
          <InfoName>
            Tipo de Permiso: <span>{infoPermission?.action}</span>
          </InfoName>
          <ButtonPrimary click={changeUpdated}>Actualizar</ButtonPrimary>
        </>
      ) : (
        <ContainerUpdated>
          <Select
            label="Tipo de permiso"
            data={typesPermissions}
            onChange={changeAction}
          />
          <ContainerButtons>
            <ButtonPrimary click={updatePermissionAction}>
              Actualizar
            </ButtonPrimary>
            <span />
            <ButtonSecondary click={changeUpdated}>Cancelar</ButtonSecondary>
          </ContainerButtons>
        </ContainerUpdated>
      )}
    </Form>
  );
};

export default FormPermission;
