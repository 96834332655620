import { Customer } from "../../../domain/models/Customer";

export const validatorCustomer = (customer: Customer, error: any) => {
  let {
    dniErrorText,
    dniError,
    nameErrorText,
    nameError,
    lastNameError,
    lastNameErrorText,
    aliasError,
    aliasErrorText,
    districtError,
    districtErroText,
    locationError,
    locationErrorText,
  } = error;
  const { alias, dni, lastname, name, district } = customer;

  if (dni === "") {
    dniError = true;
    dniErrorText = "Ingrese dni";
  } else if (dni.trim().length < 8) {
    dniError = true;
    dniErrorText = "Ingrese dni valido";
  } else {
    dniError = false;
    dniErrorText = "";
  }

  if (name === "") {
    nameError = true;
    nameErrorText = "Ingrese nombres";
  } else {
    nameError = false;
    nameErrorText = "";
  }

  if (lastname === "") {
    lastNameError = true;
    lastNameErrorText = "Ingrese apellidos";
  } else {
    lastNameError = false;
    lastNameErrorText = "";
  }

  if (alias === "") {
    aliasError = true;
    aliasErrorText = "Ingrese alias";
  } else {
    aliasError = false;
    aliasErrorText = "";
  }

  if (district === "") {
    districtError = true;
    districtErroText = "Ingrese distrito";
  } else {
    districtError = false;
    districtErroText = "";
  }

  // if (location === "") {
  //   locationError = true;
  //   locationErrorText = "Ingrese dirección";
  // } else {
  //   locationError = false;
  //   locationErrorText = "";
  // }

  if (
    dniError ||
    nameError ||
    lastNameError ||
    districtError ||
    locationError ||
    (dniError && nameError && lastNameError && districtError && locationError)
  ) {
    return {
      dniErrorText,
      dniError,
      nameErrorText,
      nameError,
      lastNameError,
      lastNameErrorText,
      aliasError,
      aliasErrorText,
      districtError,
      districtErroText,
      locationError,
      locationErrorText,
    };
  } else {
    return false;
  }
};
