import styled from "styled-components";

export const ContainerButtons = styled.div`
  display: flex;
  margin: 20px 0px;
  flex-wrap: wrap;
  div {
    margin: 0px 20px;
  }
`;

export const ContainerChips = styled.div`
  padding: 20px;
`;
