import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../../domain/services/user.service";
import { getToken } from "../../../helpers/constants";
import { validatorCollector } from "../../../helpers/validations/colector";
import { useForm } from "../../../hooks/useForm";
import { ButtonPrimary, ButtonSecondary } from "../../ui/Buttons";
import { InputText, InputNumber, InputName } from "../../ui/Inputs";
import { ModalError, ModalSuccess } from "../../ui/Modals";
import { ContainerButton, GridInput } from "./style";

const FormCollector = () => {
  const navigate = useNavigate();

  const { form, onChange } = useForm({
    dni: "",
    name: "",
    lastname: "",
    user: "",
    password: "",
    idProfile: process.env.REACT_APP_ID_COLLECTOR,
  });

  const { form: formError, onChangeError } = useForm({
    dniErrorText: "",
    dniError: false,
    nameErrorText: "",
    nameError: false,
    lastNameError: false,
    lastNameErrorText: "",
    userError: false,
    userErrorText: "",
    passwordError: false,
    passwordErrorText: "",
  });

  const [openSuccess, setOpenSuccess] = React.useState<boolean>(false);
  const [openError, setOpenError] = React.useState<boolean>(false);

  const createUserCollector = async () => {
    const validateCollector = validatorCollector(form, formError);
    if (validateCollector) {
      onChangeError(validateCollector);
    } else {
      onChangeError({
        dniErrorText: "",
        dniError: false,
        nameErrorText: "",
        nameError: false,
        lastNameError: false,
        lastNameErrorText: "",
        userError: false,
        userErrorText: "",
        passwordError: false,
        passwordErrorText: "",
      });
      const response = await userService.postUserCollector(form, getToken());
      if (response) {
        setOpenSuccess(!openSuccess);
      } else {
        setOpenError(!openError);
      }
    }
  };

  return (
    <div>
      <Grid container>
        <GridInput item xs={12} sm={6} md={4}>
          <InputNumber
            label="DNI"
            value={form.dni}
            name="dni"
            handleChange={onChange}
            maxLenght={8}
            error={formError.dniError}
            errorText={formError.dniErrorText}
          />
        </GridInput>
        <GridInput item xs={12} sm={6} md={4}>
          <InputName
            label="Nombres"
            value={form.name}
            name="name"
            handleChange={onChange}
            error={formError.nameError}
            errorText={formError.nameErrorText}
          />
        </GridInput>
        <GridInput item xs={12} sm={6} md={4}>
          <InputName
            label="Apellidos"
            value={form.lastname}
            name="lastname"
            handleChange={onChange}
            error={formError.lastNameError}
            errorText={formError.lastNameErrorText}
          />
        </GridInput>
        <GridInput item xs={12} sm={6} md={4}>
          <InputText
            label="Usuario"
            value={form.user}
            name="user"
            handleChange={onChange}
            error={formError.userError}
            errorText={formError.userErrorText}
          />
        </GridInput>
        <GridInput item xs={12} sm={6} md={4}>
          <InputText
            label="Contraseña"
            value={form.password}
            name="password"
            handleChange={onChange}
            error={formError.passwordError}
            errorText={formError.passwordErrorText}
          />
        </GridInput>
      </Grid>
      <ContainerButton>
        <ButtonPrimary disabled={false} click={createUserCollector}>
          Crear cobrador
        </ButtonPrimary>
        <span />
        <ButtonSecondary
          disabled={false}
          click={() => navigate("/financiera/cobradores")}
        >
          Cancelar
        </ButtonSecondary>
      </ContainerButton>
      <ModalSuccess
        open={openSuccess}
        text="Se creo correctamente el cobrador"
        actionSuccess={() => navigate("/financiera/cobradores")}
        textSuccess="Ver Cobradores"
      />
      <ModalError
        open={openError}
        text="Hubieron problemas al crear el cobrador"
        actionSuccess={() => setOpenError(!openError)}
        textSuccess="Reintentar"
      />
    </div>
  );
};

export default FormCollector;
