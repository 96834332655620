import styled from "styled-components";

export const ContainerClose = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const TextModal = styled.p`
  font-weight: 600;
  font-size: 20px;
  margin: 10px 0px;
  text-align: center;
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CustomDialog = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;
