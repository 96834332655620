import styled from "styled-components";

export const ContainerButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  div {
    margin: 0px 20px;
  }
`;

export const Total = styled.p`
  font-size: 40px;
  font-weight: bold;
  margin-top: 5px;
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.08em;
  display: block;
  margin-bottom: 5px;
  color: ${(props) => props.theme.colors.text};
`;

export const Wrap = styled.div`
  flex-grow: 1;
  margin-right: 20px;
`;

export const ContainerCustomer = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-start;
  @media (max-width: 900px) {
    width: 100%;
  }
`;
