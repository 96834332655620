import { http } from "../http/http";

const URL = process.env.REACT_APP_URL;

export const profileRepository = {
  getAllProfiles: async (token: string) => {
    const response = await http.get(`${URL}/profiles`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  getIdProfile: async (id: string, token: string) => {
    const response = await http.get(`${URL}/${id}`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
};
