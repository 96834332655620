import React from "react";
import { Paragraph, Title } from "../components/ui/Texts";
import CardsBooks from "../components/containers/CardsBooks";

const Expired = () => {
  return (
    <div>
      <Title>Cartillas Vencidas</Title>
      <Paragraph>
        Visualizador de cartillas que ya vencieron en el sistema y tiene monto
        por cobrar
      </Paragraph>
      <CardsBooks idType={""} type="cartillas-vencidas" expired />
    </div>
  );
};

export default Expired;
