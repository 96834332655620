import { http } from "../http/http";

const URL = process.env.REACT_APP_URL;

export const districtRepository = {
  getAllDistricts: async (token: string) => {
    const response = await http.get(`${URL}/districts`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
};
