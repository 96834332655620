import React from "react";
import { useNavigate } from "react-router-dom";
import CardsCustomers from "../components/containers/CardsCustomers";
import { ButtonPrimary } from "../components/ui/Buttons";
import { Paragraph, Subtitle, Title } from "../components/ui/Texts";

const Customers = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Title>Clientes</Title>
      <Paragraph>Visualizador de clientes</Paragraph>
      <ButtonPrimary click={() => navigate("nuevo-cliente")}>
        Crear cliente
      </ButtonPrimary>
      <Subtitle>Lista de clientes</Subtitle>
      <CardsCustomers />
    </div>
  );
};

export default Customers;
