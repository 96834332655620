import React from "react";
import {
  Container,
  Label,
  LabelError,
  Options,
  OptionSelected,
  SelectNutti,
  TextOption,
} from "./style";

interface ISelectProps {
  label: string;
  data: any[];
  onChange: any;
  error?: boolean;
  errorText?: string;
  reset?: boolean;
  optionValue?: any;
}

const Select: React.FC<ISelectProps> = ({
  label,
  data,
  onChange,
  error = false,
  errorText = "",
  reset = false,
  optionValue = "Escoger opción",
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [option, setOption] = React.useState<string | number>(optionValue);

  const onChangeOption = (value: any) => {
    setOption(value.name);
    setOpen(false);
    onChange(value);
  };

  const onResetOption = () => {
    setOption("Escoger opción");
  };

  React.useEffect(() => {
    if (reset) {
      onResetOption();
    }
  }, [reset]);

  React.useEffect(() => {
    setOption(optionValue);
  }, [optionValue]);

  return (
    <Container onMouseLeave={() => setOpen(false)}>
      <Label open={open} error={error}>
        {label}
      </Label>
      <SelectNutti onClick={() => setOpen(!open)} open={open} error={error}>
        <OptionSelected>{option}</OptionSelected>
        {/* <img src={arrow_down} alt="arrow_down" /> */}
      </SelectNutti>
      {open && (
        <Options>
          {data?.map((item, index) => (
            <TextOption key={index} onClick={() => onChangeOption(item)}>
              {item.name}
            </TextOption>
          ))}
        </Options>
      )}
      {error && <LabelError>{errorText}</LabelError>}
    </Container>
  );
};

export default Select;
