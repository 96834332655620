import styled from "styled-components";

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;


export const Title = styled.p`
  color: ${(props) => props.theme.colors.text};
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  margin-bottom: 40px;
`;

export const Form = styled.div`
  background: #ffffff;
  width: 530px;
  padding: 25px;
  box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 600px) {
    width: 400px;
  }

  @media (max-width: 425px) {
    width: 350px;
  }

  @media (max-width: 375px) {
    width: 280px;
  }
`;

export const ContainerForm = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
