import { ThemeProvider } from "styled-components";
import GlobalStyle from "../src/infraestructure/assets/styles/global";
import { myTheme } from "./infraestructure/assets/styles/my-theme";
import RoutesApp from "./infraestructure/routes";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={myTheme}>
        <RoutesApp />
      </ThemeProvider>
    </>
  );
}

export default App;
