import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Information, Name } from "./style";

interface ICardCollectorProps {
  name: string;
  lastname: string;
  dni: string;
  user: string;
  password: string;
  _id: string;
}

const CardCollector: React.FC<ICardCollectorProps> = ({ ...item }) => {
  const navigate = useNavigate();

  return (
    <Card onClick={() => navigate(item._id)}>
      <Name>
        {item.name} {item.lastname}
      </Name>
      <Information>
        <span>DNI : </span> {item.dni}
      </Information>
      <Information>
        <span>Usuario : </span> {item.user}
      </Information>
    </Card>
  );
};

export default CardCollector;
