import React from "react";
import ContainerDashboard from "../components/containers/ContainerDashboard";
import { Paragraph, Title } from "../components/ui/Texts";

const Dashboard = () => {
  return (
    <div>
      <Title>Dashboard</Title>
      <Paragraph>Revisar las cartillas que tocan cobrar</Paragraph>
      <ContainerDashboard />
    </div>
  );
};

export default Dashboard;
