import React from "react";
import FormBook from "../components/elements/FormBook";
import { Paragraph, Subtitle, Title } from "../components/ui/Texts";

const Primers = () => {
  return (
    <div>
      <Title>Cartillas</Title>
      <Paragraph>Creación de cartillas</Paragraph>
      <Subtitle>Información de cartilla</Subtitle>
      <FormBook />
    </div>
  );
};

export default Primers;
