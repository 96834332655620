import { Grid } from "@mui/material";
import React from "react";
import { Customer } from "../../../../domain/models/Customer";
import { Card, Container, Information, Name } from "./style";

interface ISearchCustomers {
  data: Customer[];
  infoCustomer: Customer | any;
  selectedCustomer: any;
}

const SearchCustomers: React.FC<ISearchCustomers> = ({
  data,
  infoCustomer,
  selectedCustomer,
}) => {
  return (
    <Container>
      <Grid container spacing={2}>
        {data?.map((item, index) => (
          <Grid item xs={12} md={3} sm={6} key={index}>
            <Card
              onClick={() => selectedCustomer(item)}
              customer={item}
              infoCustomer={infoCustomer}
            >
              <Name>
                {item.name} {item.lastname}
              </Name>
              <p>{item.alias}</p>
              <Information>
                DNI: <span>{item.dni}</span>
              </Information>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SearchCustomers;
