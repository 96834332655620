export const formatToday = () => {
  let day: any = new Date().getDate();
  let month: any = new Date().getMonth() + 1;
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  const year = new Date().getFullYear();
  return `${year}-${month}-${day}`;
};

export const replaceSeparatorDate = (date: string) => {
  const newDate = date.replaceAll("-", "/");
  return newDate;
};

export const formatDMY = (date: any) => {
  let day: any = new Date(date).getDate();
  let month: any = new Date(date).getMonth() + 1;
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  const year = new Date(date).getFullYear();
  return `${day}/${month}/${year}`;
};

export const formatYMD = (date: any) => {
  console.log("date", date);
  const [dayM, monthM, yearM] = date.split("/");
  const dateM = `${yearM}-${monthM}-${dayM}`;
  console.log("dateM", dateM);
  return `${yearM}-${monthM}-${dayM}`;
};

export const formatStartDate = (date: any | string) => {
  if (date) {
    const [dateSelected] = date.split("T");
    const parseDate = replaceSeparatorDate(dateSelected);
    const day = formatDMY(parseDate);
    return day;
  } else {
    return "";
  }
};

export const currentDay = () => {
  let day: any = new Date().getDate();
  let month: any = new Date().getMonth() + 1;
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  const year = new Date().getFullYear();
  return `${day}/${month}/${year}`;
};
