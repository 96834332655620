import { Chip, Grid } from "@mui/material";
import React from "react";
import { District } from "../../../../domain/models/District";
import { Road } from "../../../../domain/models/Road";
import { User } from "../../../../domain/models/User";
import { districtService } from "../../../../domain/services/district.service";
import { roadService } from "../../../../domain/services/road.service";
import { userService } from "../../../../domain/services/user.service";
import { getToken } from "../../../helpers/constants";
import { ButtonPrimary, ButtonSecondary } from "../../ui/Buttons";
import { InputText } from "../../ui/Inputs";
import Select from "../../ui/Select";
import { Subtitle } from "../../ui/Texts";
import { ContainerButtons, ContainerChips } from "./style";
import { useForm } from "../../../hooks/useForm";
import { useNavigate } from "react-router-dom";
import { validatorRoad } from "../../../helpers/validations/road";
import { ModalError, ModalSuccess } from "../../ui/Modals";

const FormRoad = () => {
  const navigate = useNavigate();

  const [districts, setDistricts] = React.useState<District[]>([]);
  const [collectors, setCollectors] = React.useState<User[]>([]);

  const [infoRoad, setInfoRoad] = React.useState<Road>({
    name: "",
    districts: [],
    collectors: [],
  });

  const { form, onChangeError } = useForm({
    nameError: false,
    nameErrorText: "",
    districtsError: false,
    districtsErrorText: "",
    collectorsError: false,
    collectorsErrorText: "",
  });

  const [openSuccess, setOpenSuccess] = React.useState<boolean>(false);
  const [openError, setOpenError] = React.useState<boolean>(false);

  const getDistricts = async () => {
    const data = await districtService.getAllDistricts(getToken());
    setDistricts(data);
  };

  const getCollectors = async () => {
    const data = await userService.getUsersCollectors(getToken());
    setCollectors(data);
  };

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInfoRoad({
      ...infoRoad,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeDistrict = (district: District) => {
    const isExist = roadService.saveDistrictsSelecteds(
      infoRoad.districts,
      district
    );
    if (!isExist) {
      setInfoRoad({
        ...infoRoad,
        districts: [...infoRoad.districts, district],
      });
    }
  };

  const deleteDistrict = (district: District) => {
    const districts = roadService.deleteDistrictSelected(
      infoRoad.districts,
      district
    );
    setInfoRoad({
      ...infoRoad,
      districts,
    });
  };

  const onChangeCollector = (collector: User) => {
    const isExist = roadService.saveCollectorsSelecteds(
      infoRoad.collectors,
      collector
    );
    if (!isExist) {
      setInfoRoad({
        ...infoRoad,
        collectors: [...infoRoad.collectors, collector],
      });
    }
  };

  const deleteCollector = (collector: User) => {
    const collectors = roadService.deleteCollectorSelected(
      infoRoad.collectors,
      collector
    );
    setInfoRoad({
      ...infoRoad,
      collectors,
    });
  };

  React.useEffect(() => {
    getDistricts();
    getCollectors();
  }, []);

  const createRoad = async () => {
    const validateRoad = validatorRoad(infoRoad, form);
    if (validateRoad) {
      onChangeError(validateRoad);
    } else {
      const response = await roadService.createRoad(infoRoad, getToken());
      if (response) {
        setOpenSuccess(!openSuccess);
      } else {
        setOpenError(!openError);
      }
      onChangeError({
        nameError: false,
        nameErrorText: "",
        districtsError: false,
        districtsErrorText: "",
        collectorsError: false,
        collectorsErrorText: "",
      });
    }
  };

  return (
    <div>
      <InputText
        label="Nombre de la ruta"
        name="name"
        value={infoRoad.name}
        handleChange={onChangeName}
        error={form.nameError}
        errorText={form.nameErrorText}
      />
      <Subtitle>Seleccionar distritos</Subtitle>
      <Grid container>
        <Grid item xs={12} md={6} sm={12}>
          <Select
            data={districts}
            label="Escoger distrito"
            onChange={onChangeDistrict}
            error={form.districtsError}
            errorText={form.districtsErrorText}
          />
        </Grid>
        <Grid item xs={12} md={6} sm={12}>
          <ContainerChips>
            {infoRoad.districts?.map((item, index) => (
              <Chip
                variant="outlined"
                label={item.name}
                onDelete={() => deleteDistrict(item)}
                key={index}
              />
            ))}
          </ContainerChips>
        </Grid>
      </Grid>
      <Subtitle>Seleccionar cobradores</Subtitle>
      <Grid container>
        <Grid item xs={12} md={6} sm={12}>
          <Select
            data={collectors}
            label="Escoger cobradores"
            onChange={onChangeCollector}
            error={form.collectorsError}
            errorText={form.collectorsErrorText}
          />
        </Grid>
        <Grid item xs={12} md={6} sm={12}>
          <ContainerChips>
            {infoRoad.collectors?.map((item, index) => (
              <Chip
                variant="outlined"
                label={item.name}
                onDelete={() => deleteCollector(item)}
                key={index}
              />
            ))}
          </ContainerChips>
        </Grid>
      </Grid>
      <ContainerButtons>
        <ButtonPrimary click={createRoad}>Crear Ruta</ButtonPrimary>
        <div />
        <ButtonSecondary click={() => navigate("/financiera/rutas")}>
          Cancelar
        </ButtonSecondary>
      </ContainerButtons>
      <ModalSuccess
        open={openSuccess}
        text="Se creo correctamente la ruta"
        actionSuccess={() => navigate("/financiera/rutas")}
        textSuccess="Ver Rutas"
      />
      <ModalError
        open={openError}
        text="Hubieron problemas al crear la ruta"
        actionSuccess={() => setOpenError(!openError)}
        textSuccess="Reintentar"
      />
    </div>
  );
};

export default FormRoad;
