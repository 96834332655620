import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Information, Name } from "./style";

interface IProfile {
  _id: string;
  name: string;
}

interface ICardModuleProps {
  _id: string;
  name: string;
  idProfile: IProfile;
}

const CardModule: React.FC<ICardModuleProps> = ({ ...item }) => {
  const navigate = useNavigate();
  return (
    <Card onClick={() => navigate(`/financiera/permisos/modulos/${item._id}`)}>
      <Name>{item.name}</Name>
      <Information>
        <span>Tipo de perfil : </span> {item.idProfile.name}
      </Information>
    </Card>
  );
};

export default CardModule;
