import styled from "styled-components";
import { myTheme } from "../../../assets/styles/my-theme";
import { styled as styledM } from "@mui/material/styles";
import { Button } from "@mui/material";

interface ILabelProps {
  error?: boolean;
}
interface IInputProps {
  error?: boolean;
}
// export const ButtonFile = styled.label`
//   background: white;
//   border-radius: 5px;
//   color: red;
//   /* padding: 15px 3%; */
//   outline: none;
//   cursor: pointer;
//   font-size: 18px;
//   margin: 10px 0px;
//   font-weight: 500;
//   border: 1px solid red;
// `;

export const ButtonFile = styledM(Button)(() => ({
  background: myTheme.colors.default,
  borderRadius: "5px",
  color: myTheme.colors.primary,
  padding: "15px 3%",
  outline: "none",
  cursor: "pointer",
  fontSize: "18px",
  margin: "10px 0px",
  fontWeight: 500,
  textTransform: "initial",
  border: `1px solid ${myTheme.colors.primary}`,
  width: "100%",
  "&:hover": {
    background: myTheme.colors.default,
  },
}));

export const LabelError = styled.label`
  color: ${(props) => props.theme.colors.error};
  margin: 5px;
  font-weight: 500;
  font-size: 12px;
`;

export const Label = styled.label<ILabelProps>`
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.08em;
  display: block;
  margin-bottom: 5px;
  color: ${(props) =>
    props.error ? props.theme.colors.error : props.theme.colors.text};
`;

export const Input = styled.input<IInputProps>`
  border: 1.5px solid
    ${(props) =>
      props.error ? props.theme.colors.error : props.theme.colors.text};
  padding: 15px;
  border-radius: 8px;
  transition: all 0.5s ease;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text};
  font-weight: 500;
  width: 100%;
`;

export const ContainerInput = styled.div`
  :focus-within ${Label} {
    color: ${(props) => props.theme.colors.primary};
  }
  :focus-within ${Input} {
    border: 1.5px solid ${(props) => props.theme.colors.primary};
  }
`;
