import { Grid } from "@mui/material";
import React from "react";
import { bookService } from "../../../../domain/services/book.service";
import {
  getToken,
  getTypeUser,
  getUser,
  USER_ADMIN,
} from "../../../helpers/constants";
import { formatToday } from "../../../helpers/date";
import CardMovements from "../../elements/CardMovements";
import { ButtonPrimary } from "../../ui/Buttons";
import { InputDateToday } from "../../ui/Inputs";
import Loader from "../../ui/Loader";
import { Card, Content, Information, Name } from "./style";

const ContainerReports = () => {
  const [loading, setLoading] = React.useState({
    show: true,
    isLoading: true,
    error: false,
  });
  const [day, setDay] = React.useState({
    paymentDay: formatToday(),
  });
  const [dailyFeeTotal, setDailyFeeTotal] = React.useState<number>(0);
  const [dailyFeeCurrent, setDailyFeeCurrent] = React.useState<number>(0);
  const [dailyFeeCurrentByCollectors, setDailyFeeCurrentByCollectors] =
    React.useState<any>([]);

  const onChangeDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDay({
      ...day,
      [e.target.name]: e.target.value,
    });
  };

  const getDailyFeeTotal = async () => {
    const feeTotal = await bookService.calculateFeesToday(
      day,
      getToken(),
      getUser()
    );
    setDailyFeeTotal(feeTotal);
  };

  const getDailyFeeCurrent = async () => {
    const feeCurrent = await bookService.calculateFeesTodayCurrent(
      day,
      getToken(),
      getUser()
    );
    setDailyFeeCurrent(feeCurrent);
  };

  const getDailyFeeCurrentByCollectors = async () => {
    const feeCurrent = await bookService.calculateFeesTodayCurrentByCollectors(
      day,
      getToken()
    );
    setDailyFeeCurrentByCollectors(feeCurrent);
  };

  const getExcelBooks = async () => {
    await bookService.exportExcelBooks(
      day.paymentDay,
      getToken(),
      `Reporte ${day.paymentDay}`
    );
  };

  const getTotalInformation = async () => {
    try {
      await getDailyFeeTotal();
      await getDailyFeeCurrent();
      await getDailyFeeCurrentByCollectors();
      setLoading({
        show: false,
        isLoading: false,
        error: false,
      });
    } catch (err) {
      console.log("err", err);
      setLoading({
        show: true,
        isLoading: false,
        error: true,
      });
    }
  };

  React.useEffect(() => {
    getTotalInformation();
  }, [day]);

  return (
    <>
      {loading.show ? (
        <Loader isLoading={loading.isLoading} error={loading.error} />
      ) : (
        <div>
          <div>
            <InputDateToday
              label="Seleccione el día"
              name="paymentDay"
              value={day.paymentDay}
              handleChange={onChangeDay}
            />
          </div>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={4}>
              <Card>
                <Name>Total de dinero que se espera recolectar hoy: </Name>
                <Information>S/{dailyFeeTotal.toFixed(2)}</Information>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              {getTypeUser() === USER_ADMIN ? (
                <Card>
                  <Name>Total de dinero recolectado hoy: </Name>
                  {dailyFeeCurrentByCollectors.map(
                    (item: any, index: number) => {
                      return (
                        <Content key={index}>
                          <p>{item.name}</p>
                          <p>s/{item.paymentDay.toFixed(2)}</p>
                        </Content>
                      );
                    }
                  )}
                </Card>
              ) : (
                <Card>
                  <Name>Total de dinero recolectado hoy: </Name>
                  <Information>S/{dailyFeeCurrent.toFixed(2)}</Information>
                </Card>
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Card>
                <Name>Listado de movimientos: </Name>
                <CardMovements day={day.paymentDay} />
              </Card>
            </Grid>
            {getTypeUser() === USER_ADMIN && (
              <Grid item xs={12} sm={4} md={4}>
                <Card>
                  <Name>Descargar informes: </Name>
                  <ButtonPrimary click={getExcelBooks}>
                    Descagar excel del día
                  </ButtonPrimary>
                </Card>
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </>
  );
};

export default ContainerReports;
