import { STATUS_SUCCESS } from "../../infraestructure/helpers/constants";
import { authRepository } from "../../infraestructure/repositories/auth.repository";
import { AuthUser } from "../models/Auth";
import Cookies from "js-cookie";

const signInUser = async (data: AuthUser, navigate: any) => {
  const auth = await authRepository.signIn(data);
  if (auth.status === STATUS_SUCCESS) {
    Cookies.set("tokenFinanciera", auth.data.token);
    Cookies.set("userFinanciera", JSON.stringify(auth.data.userData));
    document.location.href = "/financiera";
  } else {
    return true;
  }
};

const signOutUser = (navigate: any) => {
  Cookies.remove("tokenFinanciera");
  Cookies.remove("userFinanciera");
  document.location.href = "/";
  // navigate("/", { replace: true });
};

export const authService = {
  signInUser,
  signOutUser,
};
