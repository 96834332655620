import { http } from "../http/http";
import { Book } from "../../domain/models/Book";

const URL = process.env.REACT_APP_URL;

export const bookRepository = {
  getTypesBooks: async (token: string) => {
    const response = await http.get(`${URL}/typebooks`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  postCreateBook: async (body: Book, idType: string, token: string) => {
    const response = await http.post(
      `${URL}/books/${idType}/typeBook`,
      JSON.stringify(body),
      token
    );
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  getBooksByType: async (id: string, token: string) => {
    const response = await http.get(`${URL}/books/${id}/typeBook`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  getBooksByExpired: async (token: string) => {
    const response = await http.get(`${URL}/books/status/vencido`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  getBookById: async (id: string, token: string) => {
    const response = await http.get(`${URL}/books/${id}`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  getBookByIdPublic: async (id: string) => {
    const response = await http.getPublic(`${URL}/books/public/${id}`);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  putAmountBook: async (id: string, body: any, token: string) => {
    const response = await http.put(
      `${URL}/books/${id}`,
      JSON.stringify(body),
      token
    );
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  putAmountNewBook: async (id: string, body: any, token: string) => {
    const response = await http.put(
      `${URL}/books/updatepayandamount/${id}`,
      JSON.stringify(body),
      token
    );
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  putObservationBook: async (id: string, body: any, token: string) => {
    const response = await http.put(
      `${URL}/books/detail/${id}`,
      JSON.stringify(body),
      token
    );
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  getBooksToday: async (day: string, token: string) => {
    const response = await http.get(`${URL}/books/paymentday/${day}`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  getBooksTodayByRoutes: async (day: string, token: string) => {
    const response = await http.get(
      `${URL}/books/paymentday/${day}/routes`,
      token
    );
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  putFinishBook: async (id: string, body: any, token: string) => {
    const response = await http.put(
      `${URL}/books/${id}/statusBook`,
      JSON.stringify(body),
      token
    );
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  putArrayDaysBooks: async (id: string, body: any, token: string) => {
    const response = await http.put(
      `${URL}/books/${id}/fees`,
      JSON.stringify(body),
      token
    );
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  getBooksByClient: async (id: string, token: string) => {
    const response = await http.get(`${URL}/books/${id}/client`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  deleteBookById: async (id: string, token: string) => {
    const response = await http.delete(`${URL}/books/${id}`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  postCronJobBooks: async (body: any, token: string) => {
    const response = await http.post(
      `${URL}/botonCron`,
      JSON.stringify(body),
      token
    );
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
};
