import styled from "styled-components";

interface ICardProps {
  color: string;
}

export const Title = styled.p`
  font-size: 20px;
  font-weight: 700;
  margin: 20px 0px;
`;

export const Information = styled.div`
  margin: 5px 0px;
  span {
    font-weight: 700;
  }
`;

export const Name = styled.p`
  font-size: 20px;
  font-weight: 700;
`;

export const Card = styled.div<ICardProps>`
  padding: 15px;
  border: 1px solid ${(props) => props.theme.colors.primary};
  cursor: pointer;
  background: ${(props) => props.color};
  margin: 0px 20px;
`;
