import React from "react";
import TableBook from "../components/elements/TableBook";
import BackButton from "../components/ui/BackButton";
import { Paragraph, Subtitle, Title } from "../components/ui/Texts";

const IdDaily = () => {
  return (
    <div>
      <BackButton />
      <Title>Información de Cartilla</Title>
      <Paragraph>Visualizador de datos de la cartilla</Paragraph>
      <Subtitle>Información del pago</Subtitle>
      <TableBook type="pago-diario" />
    </div>
  );
};

export default IdDaily;
