import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import WrapperFinancial from "../components/containers/WrapperFinancial";
import Collectors from "../pages/Collectors";
import Customers from "../pages/Customers";
import Daily from "../pages/Daily";
import Dashboard from "../pages/Dashboard";
import IdCollector from "../pages/IdCollector";
import IdCustomer from "../pages/IdCustomer";
import Login from "../pages/Login";
import NewCollector from "../pages/NewCollector";
import NewCustomer from "../pages/NewCustomer";
import NewRoad from "../pages/NewRoad";
import PermissionByModule from "../pages/PermissionByModule";
import PermissionByProfile from "../pages/PermissionByProfile";
import Permissions from "../pages/Permissions";
import Books from "../pages/Books";
import Roads from "../pages/Roads";
import IdDaily from "../pages/IdDaily";
import React, { Suspense } from "react";
import Weekly from "../pages/Weekly";
import Biweekly from "../pages/Biweekly";
import Monthly from "../pages/Monthly";
import {
  getToken,
  getUser,
  USER_ADMIN,
  USER_COLLECTOR,
} from "../helpers/constants";
import { User } from "../../domain/models/User";
import ErrorPage from "../pages/Error";
import RedirectAuth from "../pages/RedirectAuth";
import BookCustomer from "../pages/BookCustomer";
import Reports from "../pages/Reports";
import Expired from "../pages/Expired";

const WrapperFinancial = React.lazy(
  () => import("../components/containers/WrapperFinancial")
);

export default function RoutesApp() {
  const [token, setToken] = React.useState<string | boolean>(false);
  const [typeUser, setTypeUser] = React.useState("COBRADOR");

  React.useEffect(() => {
    setToken(getToken());
  });

  React.useEffect(() => {
    if (token) {
      const user: User = getUser();
      setTypeUser(user.idProfile.name);
    }
  }, [token]);

  return (
    <Router>
      <Routes>
        {!token ? (
          <>
            <Route path="/">
              <Route
                path="cartillas/:idBookCustomer"
                element={<BookCustomer />}
              />
              <Route index element={<Login />} />
              <Route path="*" element={<ErrorPage auth={false} />} />
            </Route>
          </>
        ) : (
          <>
            <Route path="/" element={<RedirectAuth />}></Route>
            <Route
              path="/financiera"
              element={
                <Suspense fallback={<div></div>}>
                  <WrapperFinancial />
                </Suspense>
              }
            >
              {typeUser === USER_ADMIN && (
                <>
                  <Route path="cobradores">
                    <Route path=":idCollector" element={<IdCollector />} />
                    <Route path="nuevo-cobrador" element={<NewCollector />} />
                    <Route index element={<Collectors />} />
                  </Route>
                  <Route path="permisos">
                    <Route
                      path="modulos/:idModule"
                      element={<PermissionByModule />}
                    />
                    <Route
                      path=":idProfile"
                      element={<PermissionByProfile />}
                    />
                    <Route path="*" element={<Permissions />} />
                    <Route index element={<Permissions />} />
                  </Route>
                  <Route path="rutas">
                    <Route path="nueva-ruta" element={<NewRoad />} />
                    <Route index element={<Roads />} />
                  </Route>
                  <Route path="clientes">
                    <Route path=":idCustomer" element={<IdCustomer />} />
                    <Route path="nuevo-cliente" element={<NewCustomer />} />
                    <Route index element={<Customers />} />
                  </Route>
                  <Route path="cartillas">
                    <Route index element={<Books />} />
                  </Route>
                  <Route path="pago-diario">
                    <Route path=":idDaily" element={<IdDaily />} />
                    <Route index element={<Daily />} />
                  </Route>
                  <Route path="pago-semanal">
                    <Route path=":idDaily" element={<IdDaily />} />
                    <Route index element={<Weekly />} />
                  </Route>
                  <Route path="pago-quincenal">
                    <Route path=":idDaily" element={<IdDaily />} />
                    <Route index element={<Biweekly />} />
                  </Route>
                  <Route path="pago-mensual">
                    <Route path=":idDaily" element={<IdDaily />} />
                    <Route index element={<Monthly />} />
                  </Route>
                  <Route path="reportes">
                    <Route index element={<Reports />} />
                  </Route>
                  <Route path="cartillas-vencidas">
                    <Route index element={<Expired />} />
                  </Route>
                  <Route index element={<Dashboard />} />
                </>
              )}
              {typeUser === USER_COLLECTOR && (
                <>
                  <Route path="clientes">
                    <Route path=":idCustomer" element={<IdCustomer />} />
                    <Route path="nuevo-cliente" element={<NewCustomer />} />
                    <Route index element={<Customers />} />
                  </Route>
                  <Route path="cartillas">
                    <Route index element={<Books />} />
                  </Route>
                  <Route path="pago-diario">
                    <Route path=":idDaily" element={<IdDaily />} />
                    <Route index element={<Daily />} />
                  </Route>
                  <Route path="pago-semanal">
                    <Route path=":idDaily" element={<IdDaily />} />
                    <Route index element={<Weekly />} />
                  </Route>
                  <Route path="reportes">
                    <Route index element={<Reports />} />
                  </Route>
                  <Route path="cartillas-vencidas">
                    <Route index element={<Expired />} />
                  </Route>
                  <Route index element={<Dashboard />} />
                </>
              )}
            </Route>
            <Route path="*" element={<ErrorPage auth />} />
          </>
        )}
      </Routes>
    </Router>
  );
}
