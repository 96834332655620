import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Name } from "./style";

interface ICardRoadProps {
  _id?: string;
  name: string;
}

const CardRoad: React.FC<ICardRoadProps> = ({ ...item }) => {
  return (
    <Card>
      <Name>{item.name}</Name>
    </Card>
  );
};

export default CardRoad;
