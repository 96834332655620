import { styled as styledM } from "@mui/material/styles";
import { Button } from "@mui/material";
import { myTheme } from "../../../assets/styles/my-theme";

export const ButtonS = styledM(Button)(() => ({
  background: myTheme.colors.default,
  borderRadius: "5px",
  color: myTheme.colors.primary,
  padding: "15px 30px",
  border: `1px solid ${myTheme.colors.primary}`,
  outline: "none",
  cursor: "pointer",
  fontSize: "16px",
  margin: "10px 0px",
  fontWeight: 600,
  textTransform: "initial",
  "&:hover": {
    background: myTheme.colors.default,
  },
  "&:disabled": {
    background: "#C4C4C4",
    color: "#838383",
    "&:hover": {
      background: "#C4C4C4",
      color: "#838383",
    },
  },
}));

export const ButtonP = styledM(Button)(() => ({
  background: myTheme.colors.primary,
  borderRadius: "5px",
  color: myTheme.colors.default,
  padding: "15px 30px",
  border: "none",
  outline: "none",
  cursor: "pointer",
  fontSize: "16px",
  margin: "10px 0px",
  fontWeight: 600,
  textTransform: "initial",
  "&:hover": {
    background: myTheme.colors.primary,
  },
  "&:disabled": {
    background: "#C4C4C4",
    color: "#838383",
    "&:hover": {
      background: "#C4C4C4",
      color: "#838383",
    },
  },
}));
