import styled from "styled-components";

interface ISelectProps {
  open: boolean;
  error: boolean;
}

export const LabelError = styled.label`
  color: ${(props) => props.theme.colors.error};
  margin: 5px;
  font-weight: 500;
  font-size: 12px;
`;

export const TextOption = styled.label`
  padding: 10px 15px;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.colors.secondary};
  }
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  border: 1.5px solid ${(props) => props.theme.colors.primary};
  position: absolute;
  width: 100%;
  z-index: 30;
  border-radius: 8px;
  background: white;
  height: 150px;
  overflow-y: scroll;
`;

export const OptionSelected = styled.label`
  flex-grow: 1;
  cursor: pointer;
`;

export const Label = styled.label<ISelectProps>`
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.08em;
  display: block;
  margin-bottom: 5px;
  color: ${(props) =>
    props.error
      ? props.theme.colors.error
      : props.open
      ? props.theme.colors.primary
      : props.theme.colors.text};
  transition: all 0.5s ease;
`;

export const SelectNutti = styled.div<ISelectProps>`
  border: 1.5px solid
    ${(props) =>
      props.error
        ? props.theme.colors.error
        : props.open
        ? props.theme.colors.primary
        : props.theme.colors.text};
  padding: 15px;
  border-radius: 8px;
  transition: all 0.5s ease;
  display: flex;
  cursor: pointer;
`;

export const Container = styled.div`
  position: relative;
`;
