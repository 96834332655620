import { DefaultTheme } from "styled-components";

const myTheme: DefaultTheme = {
  colors: {
    primary: "#088AC6",
    secondary: "#F4F9FB",
    success: "#B6DEBC",
    error: "#D64550",
    text: "#1C2826",
    default: "#FFFFFF",
  },
};

export { myTheme };
