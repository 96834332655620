import React from "react";
import { useNavigate } from "react-router-dom";
import { BookM } from "../../../../domain/models/Book";
import { formatDMY } from "../../../helpers/date";
import { Card, Information, Name } from "./style";
import {
  BOOK_DAILY,
  BOOK_WEEKLY,
  BOOK_BIWEEKLY,
  BOOK_MONTHLY,
} from "../../../helpers/constants";

interface ICardBookProps {
  book: BookM;
  typeBook: string;
}

const CardBookCustomer: React.FC<ICardBookProps> = ({ ...item }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    switch (item.book.idType.name) {
      case BOOK_DAILY:
        navigate(`/financiera/pago-diario/${item.book._id}`);
        break;
      case BOOK_WEEKLY:
        navigate(`/financiera/pago-semanal/${item.book._id}`);
        break;
      case BOOK_BIWEEKLY:
        navigate(`/financiera/pago-quincenal/${item.book._id}`);
        break;
      case BOOK_MONTHLY:
        navigate(`/financiera/pago-mensual/${item.book._id}`);
        break;
      default:
        break;
    }
  };

  return (
    <Card onClick={handleNavigate} status={item.book.status}>
      <Name>
        {item.book.idClient?.name} {item.book.idClient?.lastname}
      </Name>
      <p>{item.book.idClient?.alias}</p>
      <Information>
        <span>Monto total : </span> {item.book.totalAmount}
      </Information>
      <Information>
        <span>Cuotas : </span> {item.book.totalFees}
      </Information>
      <Information>
        <span>Fecha de inicio : </span> {formatDMY(item.book.startDate)}
      </Information>
    </Card>
  );
};

export default CardBookCustomer;
