import { PermissionAction } from "../../domain/models/Permission";
import { http } from "../http/http";

const URL = process.env.REACT_APP_URL;

export const permissionRepository = {
  getPermissionByModule: async (id: string, token: string) => {
    const response = await http.get(`${URL}/permissions/${id}/module`, token);
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
  putPermissionAction: async (
    body: PermissionAction,
    id: string,
    token: string
  ) => {
    const response = await http.put(
      `${URL}/permissions/${id}`,
      JSON.stringify(body),
      token
    );
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  },
};
