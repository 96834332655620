import { Road, RoadError } from "../../../domain/models/Road";

export const validatorRoad = (road: Road, error: RoadError) => {
  let {
    collectorsError,
    collectorsErrorText,
    districtsError,
    districtsErrorText,
    nameError,
    nameErrorText,
  } = error;
  const { name, collectors, districts } = road;

  if (name === "") {
    nameError = true;
    nameErrorText = "Ingrese nombre de la ruta";
  } else {
    nameError = false;
    nameErrorText = "";
  }

  if (districts.length === 0) {
    districtsError = true;
    districtsErrorText = "Ingrese distritos";
  } else {
    districtsError = false;
    districtsErrorText = "";
  }

  if (collectors.length === 0) {
    collectorsError = true;
    collectorsErrorText = "Ingrese cobradores";
  } else {
    collectorsError = false;
    collectorsErrorText = "";
  }

  if (
    nameError ||
    collectorsError ||
    districtsError ||
    (nameError && collectorsError && districtsError)
  ) {
    return {
      collectorsError,
      collectorsErrorText,
      districtsError,
      districtsErrorText,
      nameError,
      nameErrorText,
    };
  } else {
    return false;
  }
};
