import {
  STATUS_CREATED,
  STATUS_SUCCESS,
} from "../../infraestructure/helpers/constants";
import { profileRepository } from "../../infraestructure/repositories/profile.repository";

const getAllProfiles = async (token: string) => {
  const profiles = await profileRepository.getAllProfiles(token);
  if (
    profiles.status === STATUS_CREATED ||
    profiles.status === STATUS_SUCCESS
  ) {
    return profiles.data;
  } else {
    return [];
  }
};

export const profileService = {
  getAllProfiles,
};
