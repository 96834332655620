import styled from "styled-components";
import { BOOK_DEFEATED, BOOK_PAID_OUT } from "../../../helpers/constants/index";
interface ICardProps {
  status: string;
}

export const Information = styled.div`
  margin: 5px 0px;
  span {
    font-weight: 700;
  }
`;

export const Name = styled.p`
  font-size: 20px;
  font-weight: 700;
`;

export const Card = styled.div<ICardProps>`
  padding: 15px;
  border: 1px solid ${(props) => props.theme.colors.primary};
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2);
  background: ${(props) =>
    props.status === BOOK_DEFEATED
      ? props.theme.colors.error
      : props.status === BOOK_PAID_OUT
      ? props.theme.colors.success
      : props.theme.colors.default};
  cursor: pointer;
`;
