import { Grid } from "@mui/material";
import React from "react";
import CardCustomer from "../../elements/CardCustomer";
import { Customer } from "../../../../domain/models/Customer";
import { customerService } from "../../../../domain/services/customer.service";
import { getToken } from "../../../helpers/constants";

const CardsCustomers = () => {
  const [customers, setCustomers] = React.useState<Customer[]>([]);

  const getCustomers = async () => {
    const data = await customerService.getAllCustomers(getToken());
    setCustomers(data);
  };

  React.useEffect(() => {
    getCustomers();
  }, []);

  return (
    <div>
      <Grid container spacing={3}>
        {customers.map((item, index) => {
          return (
            <Grid item xs={12} md={3} sm={6} key={index}>
              <CardCustomer {...item} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default CardsCustomers;
