import {
  BOOK_BIWEEKLY,
  BOOK_DAILY,
  BOOK_MONTHLY,
  BOOK_WEEKLY,
  PAYMENT_NOT_PAYED,
  PAYMENT_PAID_OUT,
  PAYMENT_PENDING,
  STATUS_CREATED,
  STATUS_SUCCESS,
  USER_ADMIN,
} from "../../infraestructure/helpers/constants";
import {
  formatDMY,
  formatStartDate,
  replaceSeparatorDate,
} from "../../infraestructure/helpers/date";
import { bookRepository } from "../../infraestructure/repositories/book.repository";
import { Book, BookM } from "../models/Book";
import { User } from "../models/User";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { movementsRepository } from "../../infraestructure/repositories/movements.repository";
import { userRepository } from "../../infraestructure/repositories/user.repository";

const getTypesBooks = async (token: string) => {
  const books = await bookRepository.getTypesBooks(token);
  if (books.status === STATUS_CREATED || books.status === STATUS_SUCCESS) {
    return books.data;
  } else {
    return [];
  }
};

const postCreateBook = async (data: Book, token: string) => {
  const customer = await bookRepository.postCreateBook(
    {
      ...data,
      amount: parseFloat(data.amount as any),
      missingAmount: parseFloat(data.totalAmount as any),
      createdDate: replaceSeparatorDate(data.createdDate),
      startDate: replaceSeparatorDate(data.startDate),
      dailyFee:
        parseFloat(data.totalAmount as any) / parseFloat(data.totalFees as any),
      totalAmount: Math.ceil(data.totalAmount as number),
    },
    data.idType,
    token
  );
  if (
    customer.status === STATUS_CREATED ||
    customer.status === STATUS_SUCCESS
  ) {
    return true;
  } else {
    return false;
  }
};

const formInterest = (amount: number, interest: number) => {
  const percent = interest / 100;
  const extra = amount * percent;
  const total = amount + extra;
  return total;
};

const calculateInterest = (amount: any, interest: any) => {
  const result = formInterest(parseFloat(amount), parseFloat(interest));
  return result;
};

const getBooksByType = async (idType: string, token: string, user: User) => {
  const books = await bookRepository.getBooksByType(idType, token);
  if (books.status === STATUS_CREATED || books.status === STATUS_SUCCESS) {
    if (user.idProfile.name === USER_ADMIN) {
      return books.data.reverse();
    } else {
      const booksCollector = books.data.filter(
        (item: BookM) => item.idCollector._id === user._id
      );
      return booksCollector.reverse();
    }
  } else {
    return [];
  }
};

const getBooksByExpired = async (token: string, user: User) => {
  const books = await bookRepository.getBooksByExpired(token);
  if (books.status === STATUS_CREATED || books.status === STATUS_SUCCESS) {
    if (user.idProfile.name === USER_ADMIN) {
      return books.data.reverse();
    } else {
      const booksCollector = books.data.filter(
        (item: BookM) => item.idCollector._id === user._id
      );
      return booksCollector.reverse();
    }
  } else {
    return [];
  }
};

const getBookById = async (id: string, token: string) => {
  const book = await bookRepository.getBookById(id, token);
  return book;
};

const getBookByIdPublic = async (id: string) => {
  const book = await bookRepository.getBookByIdPublic(id);
  return book;
};

const putAmountBook = async (id: string, data: any, token: string) => {
  const book = await bookRepository.putAmountBook(id, data, token);
  return book;
};

const putAmountNewBook = async (id: string, data: any, token: string) => {
  const book = await bookRepository.putAmountNewBook(id, data, token);
  return book;
};

const putObservationBook = async (id: string, data: any, token: string) => {
  const book = await bookRepository.putObservationBook(id, data, token);
  return book;
};

const getBooksToday = async (data: any, token: string, user: User) => {
  const books = await bookRepository.getBooksToday(data.paymentDay, token);
  if (books.status === STATUS_CREATED || books.status === STATUS_SUCCESS) {
    if (user.idProfile.name === USER_ADMIN) {
      return books.data.reverse();
    } else {
      const booksCollector = books.data.filter(
        (item: BookM) => item.idCollector._id === user._id
      );
      return booksCollector.reverse();
    }
  } else {
    return [];
  }
};

const getBooksTodayByRoutes = async (data: any, token: string) => {
  const books = await bookRepository.getBooksTodayByRoutes(
    data.paymentDay,
    token
  );
  if (books.status === STATUS_CREATED || books.status === STATUS_SUCCESS) {
    return books.data;
  } else {
    return {};
  }
};

const calculateFeesToday = async (data: any, token: string, user: User) => {
  const books = await bookRepository.getBooksToday(data.paymentDay, token);
  if (books.status === STATUS_CREATED || books.status === STATUS_SUCCESS) {
    if (user.idProfile.name === USER_ADMIN) {
      const feeToday = books.data.reduce(
        (previousValue: any, currentValue: any) =>
          previousValue + currentValue.dailyFee,
        0
      );
      return feeToday;
    } else {
      const booksCollector = books.data.filter(
        (item: BookM) => item.idCollector._id === user._id
      );
      const feeToday = booksCollector.reduce(
        (previousValue: any, currentValue: any) =>
          previousValue + currentValue.dailyFee,
        0
      );
      return feeToday;
    }
  } else {
    return 0;
  }
};

const calculateFeesTodayCurrent = async (
  data: any,
  token: string,
  user: User
) => {
  const books = await bookRepository.getBooksToday(data.paymentDay, token);
  if (books.status === STATUS_CREATED || books.status === STATUS_SUCCESS) {
    if (user.idProfile.name === USER_ADMIN) {
      const fees = books.data.map((item: BookM) => {
        const feeDay = item.days?.find(
          (item) =>
            formatStartDate(item.day) ===
            formatDMY(replaceSeparatorDate(data.paymentDay))
        );
        return feeDay;
      });
      const feeToday = fees
        .filter((item: any) => item !== undefined)
        .reduce(
          (previousValue: any, currentValue: any) =>
            previousValue + currentValue.dayAmount,
          0
        );
      return feeToday;
    } else {
      const booksCollector = books.data.filter(
        (item: BookM) => item.idCollector._id === user._id
      );
      const fees = booksCollector.map((item: BookM) => {
        const feeDay = item.days?.find(
          (item) =>
            formatStartDate(item.day) ===
            formatDMY(replaceSeparatorDate(data.paymentDay))
        );
        return feeDay;
      });
      const feeToday = fees
        .filter((item: any) => item !== undefined)
        // .filter((item: BookM) => item.status !== BOOK_PAID_OUT)
        .reduce(
          (previousValue: any, currentValue: any) =>
            previousValue + currentValue.dayAmount,
          0
        );
      return feeToday;
    }
  } else {
    return 0;
  }
};

const calculateFeesTodayCurrentByCollectors = async (
  data: any,
  token: string
) => {
  const collectors = await userRepository.getUserCollector(token);
  const books = await bookRepository.getBooksToday(data.paymentDay, token);
  if (books.status === STATUS_CREATED || books.status === STATUS_SUCCESS) {
    let arrayPayments = [];
    for (let index of collectors.data || []) {
      const booksCollector = books.data.filter(
        (item: BookM) => item.idCollector._id === index._id
      );
      const fees = booksCollector.map((item: BookM) => {
        const feeDay = item.days?.find(
          (item) =>
            formatStartDate(item.day) ===
            formatDMY(replaceSeparatorDate(data.paymentDay))
        );
        return feeDay;
      });
      const feeToday = fees
        .filter((item: any) => item !== undefined)
        // .filter((item: BookM) => item.status !== BOOK_PAID_OUT)
        .reduce(
          (previousValue: any, currentValue: any) =>
            previousValue + currentValue.dayAmount,
          0
        );
      arrayPayments.push({ ...index, paymentDay: feeToday });
    }
    return arrayPayments || [];
  } else {
    return [];
  }
};

const getStatusPaymentToday = (book: BookM, daySelected: string) => {
  const bookToday = book.days.find(
    (item) =>
      formatStartDate(item.day) ===
      formatStartDate(replaceSeparatorDate(daySelected))
  );
  if (bookToday?.status === PAYMENT_PAID_OUT) {
    return "#B6DEBC";
  } else {
    const index = book.days.indexOf(bookToday);
    if (index !== 0) {
      const bookYesterday = book.days[index - 1];
      if (bookYesterday?.status === PAYMENT_NOT_PAYED) {
        return "#FCFF75";
      } else {
        return "#FFD98E";
      }
    } else {
      const bookYesterday = book.days[index];
      if (bookYesterday?.status === PAYMENT_NOT_PAYED) {
        return "#FCFF75";
      } else {
        return "#FFD98E";
      }
    }
  }
};

const calculateBooksPendingToday = async (
  daySelected: any,
  token: string,
  user: User
) => {
  const books = await bookRepository.getBooksToday(daySelected, token);
  if (books.status === STATUS_CREATED || books.status === STATUS_SUCCESS) {
    if (user.idProfile.name === USER_ADMIN) {
      const booksPending = books.data.map((item: BookM) => {
        const feeDay = item.days?.find(
          (item) =>
            formatStartDate(item.day) ===
              formatDMY(replaceSeparatorDate(daySelected)) &&
            item.status === PAYMENT_PENDING
        );
        return feeDay;
      });
      return booksPending.filter((item: any) => item !== undefined).length;
    } else {
      const booksCollector = books.data.filter(
        (item: BookM) => item.idCollector._id === user._id
      );
      const booksPending = booksCollector.map((item: BookM) => {
        const feeDay = item.days?.find(
          (item) =>
            formatStartDate(item.day) ===
              formatDMY(replaceSeparatorDate(daySelected)) &&
            item.status === PAYMENT_PENDING
        );
        return feeDay;
      });
      return booksPending.filter((item: any) => item !== undefined).length;
    }
  } else {
    return 0;
  }
};

const putFinishBook = async (id: string, data: any, token: string) => {
  const book = await bookRepository.putFinishBook(id, data, token);
  if (book.status === STATUS_CREATED || book.status === STATUS_SUCCESS) {
    return true;
  } else {
    return false;
  }
};

const putArrayDaysBooks = async (id: string, data: any, token: string) => {
  const book = await bookRepository.putArrayDaysBooks(id, data, token);
  return book;
};

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const columnsExcelBook = (data: any[]) => {
  let exportData: any = [];
  if (data.length > 0) {
    data.forEach((l: any) => {
      let object = {
        "NOMBRE CLIENTE": l.customer,
        ALIAS: l.alias,
        RUTA: l.route,
        MONTO: l.amount,
        INTERES: l.interest,
        "MONTO TOTAL": l.totalAmount,
        "CUOTA DIARIA": l.fee,
        "MONTO RESTANTE": l.missingAmount,
        "ESTADO DE HOY": l.statusToday,
        "PAGO DE HOY": l.dayAmount,
        COBRADOR: l.collector,
        OBSERVACION: l.details,
      };
      exportData.push(object);
    });
  }
  return exportData;
};

const columnsExcelMovement = (data: any[]) => {
  let exportData: any = [];
  if (data.length > 0) {
    data.forEach((l: any) => {
      let object = {
        MOTIVO: l.reason,
        MONTO: l.amount,
        USUARIO: l.idUser?.name,
      };
      exportData.push(object);
    });
  }
  return exportData;
};

const getArrayBookByType = (books: any, type: string) => {
  return books
    ?.filter((item: any) => item.type === type)
    .sort(function (a: any, b: any) {
      if (a.route < b.route) {
        return -1;
      }
      if (a.route > b.route) {
        return 1;
      }
      return 0;
    });
};

const exportExcelBooks = async (
  daySelected: string,
  token: string,
  fileName: string
) => {
  const books = await bookRepository.getBooksToday(daySelected, token);
  const movements = await movementsRepository.getMovementsByDay(
    daySelected,
    token
  );
  const booksToday = books.data
    // .filter((item: BookM) => )
    .map((itemBook: BookM) => {
      const feeDay = itemBook.days?.find(
        (item) =>
          formatStartDate(item.day) ===
          formatDMY(replaceSeparatorDate(daySelected))
      );
      return {
        customer: `${itemBook.idClient?.name} ${itemBook.idClient?.lastname}`,
        alias: itemBook.idClient?.alias,
        route: itemBook.idRoute?.name,
        amount: itemBook.amount,
        interest: itemBook.interest,
        totalAmount: itemBook.totalAmount,
        missingAmount: itemBook.missingAmount,
        fee: itemBook.dailyFee,
        statusToday: feeDay?.status,
        dayAmount: feeDay?.dayAmount,
        type: itemBook.idType?.name,
        details: feeDay?.details,
        status: itemBook.status,
        collector: itemBook.idCollector?.name,
      };
    });

  const wb = {
    Sheets: {
      Cartillas_diarias: XLSX.utils.json_to_sheet(
        columnsExcelBook(
          getArrayBookByType(
            booksToday.filter(
              (item: any) => item.missingAmount >= 0 && item.dayAmount > 0
            ),
            BOOK_DAILY
          )
        )
      ),
      Cartillas_semanales: XLSX.utils.json_to_sheet(
        columnsExcelBook(
          getArrayBookByType(
            booksToday.filter(
              (item: any) => item.missingAmount >= 0 && item.dayAmount > 0
            ),
            BOOK_WEEKLY
          )
        )
      ),
      Cartillas_quincenales: XLSX.utils.json_to_sheet(
        columnsExcelBook(
          getArrayBookByType(
            booksToday.filter(
              (item: any) => item.missingAmount >= 0 && item.dayAmount > 0
            ),
            BOOK_BIWEEKLY
          )
        )
      ),
      Cartillas_mensuales: XLSX.utils.json_to_sheet(
        columnsExcelBook(
          getArrayBookByType(
            booksToday.filter(
              (item: any) => item.missingAmount >= 0 && item.dayAmount > 0
            ),
            BOOK_MONTHLY
          )
        )
      ),
      Movimientos: XLSX.utils.json_to_sheet(
        columnsExcelMovement(
          movements.data.filter(
            (item: any) =>
              formatDMY(item.registrationDate) ===
              formatDMY(replaceSeparatorDate(daySelected))
          )
        )
      ),
    },
    SheetNames: [
      "Cartillas_diarias",
      "Cartillas_semanales",
      "Cartillas_quincenales",
      "Cartillas_mensuales",
      "Movimientos",
    ],
  };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  const name = `${fileName}`;
  FileSaver.saveAs(data, name + fileExtension);
};

const getBooksByClient = async (id: any, token: string) => {
  const books = await bookRepository.getBooksByClient(id, token);
  if (books.status === STATUS_CREATED || books.status === STATUS_SUCCESS) {
    return books.data;
  } else {
    return [];
  }
};

const deleteBookById = async (navigate: any, id: string, token: string) => {
  const book = await bookRepository.deleteBookById(id, token);
  if (book.status === STATUS_CREATED || book.status === STATUS_SUCCESS) {
    navigate(-1);
  } else {
    return true;
  }
};

const postCronJobBooks = async (data: any, token: string) => {
  const book = await bookRepository.postCronJobBooks(data, token);
  if (book.status === STATUS_CREATED || book.status === STATUS_SUCCESS) {
    return true;
  } else {
    return false;
  }
};

export const bookService = {
  getTypesBooks,
  calculateInterest,
  postCreateBook,
  getBooksByType,
  getBookById,
  putAmountBook,
  getBooksToday,
  calculateFeesToday,
  calculateFeesTodayCurrent,
  getStatusPaymentToday,
  calculateBooksPendingToday,
  getBooksTodayByRoutes,
  putFinishBook,
  putArrayDaysBooks,
  exportExcelBooks,
  putObservationBook,
  putAmountNewBook,
  getBooksByClient,
  getBookByIdPublic,
  calculateFeesTodayCurrentByCollectors,
  deleteBookById,
  postCronJobBooks,
  getBooksByExpired,
};
