import { CircularProgress } from "@mui/material";
import React from "react";
import { ButtonP, ButtonS } from "./style";

interface IButtonPrimaryProps {
  children: React.ReactNode;
  disabled?: boolean;
  click: any;
  loading?: boolean;
}

export const ButtonPrimary: React.FC<IButtonPrimaryProps> = ({
  children,
  disabled = false,
  click,
  loading = false,
}) => {
  return (
    <ButtonP disabled={disabled} onClick={click}>
      {loading ? <CircularProgress color="inherit" size={20} /> : children}
    </ButtonP>
  );
};

export const ButtonSecondary: React.FC<IButtonPrimaryProps> = ({
  children,
  disabled,
  click,
}) => {
  return (
    <ButtonS disabled={disabled} onClick={click}>
      {children}
    </ButtonS>
  );
};
