import React from "react";
import NotFound from "../components/ui/NotFound";

interface IErrorPageProps {
  auth: boolean;
}

const ErrorPage: React.FC<IErrorPageProps> = ({ auth }) => {
  return (
    <div>
      <NotFound auth={auth} />
    </div>
  );
};

export default ErrorPage;
