import React from "react";
import { InputDateToday } from "../../ui/Inputs";
import { Grid } from "@mui/material";
import { formatToday } from "../../../helpers/date";
import { bookService } from "../../../../domain/services/book.service";
import { getToken, getTypeUser, USER_ADMIN } from "../../../helpers/constants";
import SliderBooks from "../../elements/SliderBooks";
import { ButtonPrimary } from "../../ui/Buttons";
import Loader from "../../ui/Loader";
import Messages from "../../ui/Messages";
import { ModalError, ModalSuccess } from "../../ui/Modals";
import { useNavigate } from "react-router-dom";

const ContainerDashboard = () => {
  const navigate = useNavigate();

  const [day, setDay] = React.useState({
    paymentDay: formatToday(),
  });
  const [booksToday, setBooksToday] = React.useState<any>();
  const [loading, setLoading] = React.useState({
    show: true,
    isLoading: true,
    error: false,
  });
  const [loadingCron, setLoadingCron] = React.useState(false);
  const [openCronSuccess, setOpenCronSuccess] = React.useState<boolean>(false);
  const [openCronError, setOpenCronError] = React.useState<boolean>(false);

  const onChangeDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDay({
      ...day,
      [e.target.name]: e.target.value,
    });
  };

  const getBooksToday = async () => {
    const books = await bookService.getBooksTodayByRoutes(day, getToken());
    setBooksToday(books);
  };

  const getTotalInformation = async () => {
    try {
      await getBooksToday();
      setLoading({
        show: false,
        isLoading: false,
        error: false,
      });
    } catch (err) {
      console.log("err", err);
      setLoading({
        show: true,
        isLoading: false,
        error: true,
      });
    }
  };

  const postCronJobBooks = async () => {
    setLoadingCron(true);
    const response = await bookService.postCronJobBooks({}, getToken());
    setLoadingCron(false);
    if (response) {
      setOpenCronSuccess(!openCronSuccess);
    } else {
      setOpenCronError(!openCronError);
    }
  };

  React.useEffect(() => {
    getTotalInformation();
  }, [day]);

  return (
    <>
      {loading.show ? (
        <Loader isLoading={loading.isLoading} error={loading.error} />
      ) : (
        <div>
          {getTypeUser() === USER_ADMIN && (
            <ButtonPrimary click={postCronJobBooks} loading={loadingCron}>
              Actualizar cartillas
            </ButtonPrimary>
          )}
          <div>
            <InputDateToday
              label="Seleccione el día"
              name="paymentDay"
              value={day.paymentDay}
              handleChange={onChangeDay}
            />
          </div>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <SliderBooks books={booksToday} daySelected={day.paymentDay} />
            </Grid>
          </Grid>
        </div>
      )}
      <ModalSuccess
        open={openCronSuccess}
        text="Se actualizaron correctamente las cartillas"
        actionSuccess={() => window.location.reload()}
        textSuccess="Reiniciar"
      />
      <ModalError
        open={openCronError}
        text="Hubieron problemas al actualizar las cartillas"
        actionSuccess={() => setOpenCronError(!openCronError)}
        textSuccess="Reintentar"
      />
    </>
  );
};

export default ContainerDashboard;
