import { AuthUser, AuthUserError } from "../../../domain/models/Auth";

export const validatorAuth = (login: AuthUser, error: AuthUserError) => {
  let { userError, passwordError, passwordErrorText, userErrorText } = error;
  const { user, password } = login;

  if (user === "") {
    userError = true;
    userErrorText = "Ingrese email";
  } else {
    userError = false;
    userErrorText = "";
  }

  if (password === "") {
    passwordError = true;
    passwordErrorText = "Ingrese contraseña";
  } else {
    passwordError = false;
    passwordErrorText = "";
  }

  if (userError || passwordError || (passwordError && userError)) {
    return { userError, passwordError, passwordErrorText, userErrorText };
  } else {
    return false;
  }
};
