import React from "react";
import CardsBooks from "../components/containers/CardsBooks";
import { Paragraph, Subtitle, Title } from "../components/ui/Texts";

const Weekly = () => {
  const idType: any = process.env.REACT_APP_ID_WEEKLY;

  return (
    <div>
      <Title>Pagos Semanales</Title>
      <Paragraph>Visualizador de cartillas de pagos semanales</Paragraph>
      <Subtitle>Lista de cartillas</Subtitle>
      <CardsBooks idType={idType} type="pago-semanal" />
    </div>
  );
};

export default Weekly;
