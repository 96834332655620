import {
  STATUS_CREATED,
  STATUS_SUCCESS,
} from "../../infraestructure/helpers/constants";
import { customerRepository } from "../../infraestructure/repositories/customer.repository";
import { Customer } from "../models/Customer";

const getAllCustomers = async (token: string) => {
  const customers = await customerRepository.getAllCustomers(token);
  if (
    customers.status === STATUS_CREATED ||
    customers.status === STATUS_SUCCESS
  ) {
    return customers.data;
  } else {
    return [];
  }
};

const postCreateCustomer = async (data: Customer, token: string) => {
  const { _id, ...dataCustomer } = data;
  const customer = await customerRepository.postCreateCustomer(
    {
      ...dataCustomer,
      alias: dataCustomer.alias.toUpperCase(),
      name: dataCustomer.name.toUpperCase(),
      lastname: dataCustomer.lastname.toUpperCase(),
    },
    token
  );
  if (
    customer.status === STATUS_CREATED ||
    customer.status === STATUS_SUCCESS
  ) {
    return true;
  } else {
    return false;
  }
};

const putCustomer = async (data: Customer, token: string) => {
  const customer = await customerRepository.putCustomer(
    data._id as string,
    {
      ...data,
      alias: data.alias.toUpperCase(),
      name: data.name.toUpperCase(),
      lastname: data.lastname.toUpperCase(),
    },
    token
  );
  if (
    customer.status === STATUS_CREATED ||
    customer.status === STATUS_SUCCESS
  ) {
    return true;
  } else {
    return false;
  }
};

const getSearchCustomers = async (word: string, token: string) => {
  const customers = await customerRepository.getSearchCustomers(word, token);
  if (
    customers.status === STATUS_CREATED ||
    customers.status === STATUS_SUCCESS
  ) {
    return customers.data;
  } else {
    return [];
  }
};

const getIdCustomer = async (id: string, token: string) => {
  const customer = await customerRepository.getIdCustomer(id, token);
  if (
    customer.status === STATUS_CREATED ||
    customer.status === STATUS_SUCCESS
  ) {
    return customer.data;
  } else {
    return {};
  }
};

const deleteIdCustomer = async (id: string, token: string) => {
  const customer = await customerRepository.deleteIdCustomer(id, token);
  if (
    customer.status === STATUS_CREATED ||
    customer.status === STATUS_SUCCESS
  ) {
    return true;
  } else {
    return false;
  }
};

export const customerService = {
  getAllCustomers,
  postCreateCustomer,
  getSearchCustomers,
  getIdCustomer,
  putCustomer,
  deleteIdCustomer,
};
