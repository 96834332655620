import styled from "styled-components";

export const TextParagraph = styled.p`
  font-weight: 300;
  font-size: 18px;
  color: ${(props) => props.theme.colors.text};
  margin: 0px 0px 10px 0px;
`;

export const TextSubtitle = styled.p`
  font-weight: 800;
  font-size: 24px;
  color: ${(props) => props.theme.colors.text};
  margin: 15px 0px 15px 0px;
`;

export const TextTitle = styled.p`
  font-weight: 800;
  font-size: 36px;
  color: ${(props) => props.theme.colors.text};
  margin: 0px 0px 10px 0px;
`;
