import React from "react";
import FormCollector from "../components/elements/FormCollector";
import { Paragraph, Subtitle, Title } from "../components/ui/Texts";

const NewCollector = () => {
  return (
    <div>
      <Title>Creación de Cobrador</Title>
      <Paragraph>Registro de cobradores nuevos</Paragraph>
      <Subtitle>Información de cobrador</Subtitle>
      <FormCollector />
    </div>
  );
};

export default NewCollector;
