import styled from "styled-components";

export const Paragraph = styled.p`
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const Subtitle = styled.p`
  font-size: 60px;
  font-weight: bold;
  color: #000000;
  @media (max-width: 900px) {
    font-size: 30px;
  }
  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const Title = styled.p`
  font-size: 300px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary};
  @media (max-width: 900px) {
    font-size: 80px;
  }
`;

export const ContainerFound = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;
