import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../../domain/services/user.service";
import { getToken } from "../../../helpers/constants";
import { useForm } from "../../../hooks/useForm";
import { ButtonPrimary, ButtonSecondary } from "../../ui/Buttons";
import { InputNumber, InputName } from "../../ui/Inputs";
import { ModalError, ModalSuccess } from "../../ui/Modals";
import Select from "../../ui/Select";
import { ContainerButton, GridInput } from "./style";

interface IFormUpdateCollectorProps {
  id: string;
}

const dataPermission = [
  {
    id: 0,
    name: "DESHABILITADO",
  },
  {
    id: 1,
    name: "HABILITADO",
  },
];

const FormUpdateCollector: React.FC<IFormUpdateCollectorProps> = ({ id }) => {
  const navigate = useNavigate();

  const { form, onChange, onCompleteData, onChangeSelect } = useForm({
    dni: "",
    name: "",
    lastname: "",
    user: "",
    password: "",
  });

  const getDataUser = async () => {
    const user = await userService.getIdUser(id, getToken());
    onCompleteData({
      ...user,
      idProfile: process.env.REACT_APP_ID_COLLECTOR,
    });
  };

  const [openSuccess, setOpenSuccess] = React.useState<boolean>(false);
  const [openError, setOpenError] = React.useState<boolean>(false);

  const putUserCollector = async () => {
    const response = await userService.putUserCollector(form, id, getToken());
    if (response) {
      setOpenSuccess(!openSuccess);
    } else {
      setOpenError(!openError);
    }
  };

  const onChangeSelectPermission = (value: any) => {
    onChangeSelect("permission", value.id);
  };

  React.useEffect(() => {
    getDataUser();
  }, []);

  return (
    <div>
      <Grid container>
        <GridInput item xs={12} sm={6} md={4}>
          <InputNumber
            label="DNI"
            value={form.dni}
            name="dni"
            handleChange={onChange}
            maxLenght={8}
            disabled
          />
        </GridInput>
        <GridInput item xs={12} sm={6} md={4}>
          <InputName
            label="Nombres"
            value={form.name}
            name="name"
            handleChange={onChange}
            disabled
          />
        </GridInput>
        <GridInput item xs={12} sm={6} md={4}>
          <InputName
            label="Apellidos"
            value={form.lastname}
            name="lastname"
            handleChange={onChange}
            disabled
          />
        </GridInput>
        <GridInput item xs={12} sm={6} md={4}>
          <Select
            data={dataPermission}
            label="Permisos"
            onChange={onChangeSelectPermission}
            optionValue={form.permission === 1 ? "HABILITADO" : "DESHABILITADO"}
          />
        </GridInput>
      </Grid>
      <ContainerButton>
        <ButtonPrimary disabled={false} click={putUserCollector}>
          Actualizar cobrador
        </ButtonPrimary>
        <span />
        <ButtonSecondary
          disabled={false}
          click={() => navigate("/financiera/cobradores")}
        >
          Cancelar
        </ButtonSecondary>
      </ContainerButton>
      <ModalSuccess
        open={openSuccess}
        text="Se actualizo correctamente el cobrador"
        actionSuccess={() => navigate("/financiera/cobradores")}
        textSuccess="Ver Cobradores"
      />
      <ModalError
        open={openError}
        text="Hubieron problemas al actualizar el cobrador"
        actionSuccess={() => setOpenError(!openError)}
        textSuccess="Reintentar"
      />
    </div>
  );
};

export default FormUpdateCollector;
