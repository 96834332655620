import React from "react";
import { useNavigate } from "react-router-dom";
import { District } from "../../../../domain/models/District";
import { Card, Information, Name } from "./style";

interface ICardCustomerProps {
  name: string;
  lastname: string;
  dni: string;
  district: District | string;
  alias: string;
  _id?: string;
}

const CardCustomer: React.FC<ICardCustomerProps> = ({ ...item }) => {
  const navigate = useNavigate();

  return (
    <Card onClick={() => navigate(item._id as string)}>
      <Name>
        {item.name} {item.lastname}
      </Name>
      <p>{item.alias}</p>
      <Information>
        <span>DNI : </span> {item.dni}
      </Information>
      <Information>
        <span>Distrito : </span> {item.district}
      </Information>
    </Card>
  );
};

export default CardCustomer;
