import styled from "styled-components";

export const ContainerButton = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.text};
  align-items: center;
  margin-left: -10px;
  font-size: 20px;
  margin-bottom: 10px;
`;
