import React from "react";
import { Container, ContainerBlue, ContainerWhite } from "./style";

interface IWrapperAuthProps {
  children: React.ReactNode;
}

const WrapperAuth: React.FC<IWrapperAuthProps> = ({ children }) => {
  return (
    <Container>
      <ContainerWhite />
      <ContainerBlue />
      {children}
    </Container>
  );
};

export default WrapperAuth;
